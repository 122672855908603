import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { ErrorOutlined } from "@material-ui/icons";

const styles = (theme) => ({
  errorIcon: {
    height: "100%",
    position: "absolute",
    background: "#fecb00",
    width: "50px",
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #fecb00",
    textAlign: "center",
  },
  errorMessage: {
    background: "#494d4e",
    color: "#fff",
    width: "calc(100% - 50px)",
    margin: 0,
    border: "1px solid #fecb00",
    display: "inline-block",
    padding: "10px",
    marginLeft: "50px",
  },

  errorContainer: {
    position: "relative",
    width: "100%",
  },
});

class Error extends Component {
  render() {
    const { error, classes } = this.props;

    return (
      <div className={classes.errorContainer}>
        <div className={classes.errorIcon}>
          <ErrorOutlined
            color="error"
            style={{ fontSize: 20, color: "#494d4e" }}
          />
        </div>
        <div className={classes.errorMessage}>
          <span>{error.message || `Error : ${error}`}</span>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Error);
