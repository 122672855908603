import {
	ACCOUNTS_REQUESTED, ACCOUNTS_RECEIVED, ACCOUNTS_ERROR,
	DOCUMENTS_REQUESTED, DOCUMENTS_RECEIVED, DOCUMENTS_ERROR, CONFIG_REQUESTED, CONFIG_RECEIVED, CONFIG_ERROR, DOCUMENT_DOWNLOADING, DOCUMENT_DOWNLOADED
} from '../actions';
import { PAYMENT_SUCCESS, PAYMENT_LOADING, PAYMENT_FAIL,PAYMENT_PROCESSING } from '../actions/payment/payment.action'
const initialState = {
	user: {
		loading: false,
		accounts: [

		],
		error: false,
		message: '',
		payment: {
			loading: false,
			error: false,
			message: '',
			billId: null,
			inputsData: [],
			url: "",
			method: "",
			info: "",
			title: ""
		}
	},
	files: {
		loading: false,
		downloading: false,
	},
	config: {
		loading: false,
		message: "",
		config: {},
		pages: [],
		menu: [],
		faults: [],
	},
};

export const config = (state = initialState.config, action) => {

	switch (action.type) {
		case CONFIG_REQUESTED:
			return {
				...state,
				loading: true
			}
		case CONFIG_RECEIVED:
			return {
				...state,
				loading: false,
				...action.config
			}
		case CONFIG_ERROR:
			return {
				...state,
				loading: false,
				message: action.error
			}

		default:

			if (localStorage.getItem('config')) {
				let config = JSON.parse(localStorage.getItem('config'));

				return {
					...state,
					...config,
				}
			}


			return state;
	}
}


export const user = (state = initialState.user, action) => {

	switch (action.type) {
		case ACCOUNTS_REQUESTED:
			return {
				...state,
				loading: true
			}

		case ACCOUNTS_RECEIVED:
			return {
				...state,
				loading: false,
				error: false,
				message: '',
				accounts: action.accounts
			}

		case ACCOUNTS_ERROR:
			return {
				...state,
				loading: false,
				error: true,
				message: action.error,
			}

		case PAYMENT_LOADING:
			return {
				...state,
				payment: {
					loading: true,
					error: false,
					billId: action.billId
				}
			}

		case PAYMENT_SUCCESS:
			return {
				...state,
				payment: {
					...state.payment,
					loading: false,
					error: false,
					inputsData: action.inputsData,
					url: action.url,
					method: action.method,
					title: action.title,
					info: action.info
				}
			}

		case	PAYMENT_PROCESSING:
				return {
					...state,
					payment: {
						...state.payment,
						loading:false,
						error: true,
						message: action.error,
					}
				}

		case PAYMENT_FAIL:
			return {
				...state,
				error: true,
				message: action.error,
				payment: {
					loading:false
				}
			}

		default:
			return state;
	}
}

export const documents = (state = initialState.files, action) => {
	switch (action.type) {
		case DOCUMENTS_REQUESTED:
			return {
				...state,
				loading: true

			}

		case DOCUMENTS_RECEIVED:
			return {
				...state,
				loading: false,
				[action.data.account]: {
					...state[action.data.account],
					documents: action.data.documents
				}
			}

		case DOCUMENTS_ERROR:
			return {
				...state,
				loading: false
			}

		case DOCUMENT_DOWNLOADING:
			return {
				...state,
				downloading: action.document
			}

		case DOCUMENT_DOWNLOADED:
			return {
				...state,
				downloading: false
			}

		default:
			return state;
	}
}


