import axios from 'axios';
import { push } from 'connected-react-router';

import { ACCOUNTS_REQUESTED } from './../index'

export const PAYMENT_LOADING = 'PAYMENT_LOADING';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAIL = 'PAYMENT_FAIL';
export const PAYMENT_PROCESSING = 'PAYMENT_PROCESSING'

export const getPaymentForm = (userId, billId, amount, currency) => {

  return dispatch => {
    dispatch({
      type: PAYMENT_LOADING,
      billId: billId
    })

    let phone = localStorage.getItem('phone')
    let hashedMsisdn = localStorage.getItem('hashedMsisdn')
    var token = localStorage.getItem('token');

    let data = {
      "id": [
        {
          "value": userId
        }
      ],
      "name": "Process bill payment",
      "desc": "Process bill payment",
      "type": "creditcard",
      "category": [
        {
          "value": "creditcard"
        }
      ],
      "status": "Awaiting Authorisation",
      "relatedParty": [
        {
          "id": [
            {
              "value": hashedMsisdn+"_"+phone
            }
          ]
        }
      ],
      "parts": {
        "channel": {
          "id": [
            {
              "value": "ebill"
            }
          ],
          "name": "Ebill Web",
          "type": "online",
          "category": [
            {
              "value": "web"
            }
          ]
        },
        "paymentMethod": {
          "cardPayment": [
            {
              "name": "Card Payment"
            }
          ]
        },
        "authorisation": {
          "amount": {
            "currencyId": "GBP",
            "value": "120.00"
          },
          "dateTime": {
            "value": "2016-01-01T00:00:00Z"
          }
        },
        "charge": {
          "amount": {
            "currencyId": "GBP",
            "value": "2.00"
          },
          "dateTime": {
            "value": "2016-01-01T00:00:00Z"
          }
        },
        "lineItem": [
          {
            "id": [
              {
                "value": billId
              }
            ],
            "name": "Ebill Name",
            "desc": "Ebill Desc",
            "type": "customerbill",
            "status": "Active",
            "quantity": {
              "unitCode": "Number",
              "value": "1"
            },
            "amount": {
              "currencyId": currency,
              "value": amount
            }
          }
        ]
      }
    }

    var headers = {
      "Content-Type": "application/json",
      "X-Source-System": "selfcare",
      "X-Source-Operator": "myvodafone",
      "X-Source-CountryCode": "al",
      "Accept-Language": "al",
      "Authorization": "Bearer "+token,
    }
    let url ='https://api-mobile-ci-vfal.vodafone.com/auth/processPaymentAPI/v2/processPayment/authorisePayment'
    // let url = 'http://52.29.171.250:8001/public/processpaymentAPI-ebill/v2/processPayment/authorisePayment'

    return axios.post(url, data, {
      headers
    }).then((res) => {
      if (res.data.errorCode) {
        // in case payment process has staratedt 
        return dispatch({
          type: PAYMENT_PROCESSING,
          error: res.data.message
        })
      }
      let method
      let url
      let formData = res.data.parts.session[0].specification.characteristicsValue
      // let formData = res.data.parts.session[0].specification[0]
      let inputsData = formData.filter(data => {
        if (data.characteristicName === "method") {
          method = data.value
          return false
        }
        if (data.characteristicName === "URL") {
          url = data.value
          return false
        }
        if(data.characteristicName === "data")
          return false
        return true
      })

      dispatch({
        type: PAYMENT_SUCCESS,
        inputsData,
        url,
        method,
        amount: {
          value: amount,
          currency: currency
        },
        title: res.data.desc,
        info: res.data.parts.lineItem[0].desc
      })
      
      localStorage.setItem('allowPaymentAccess', true)
      dispatch(push('/payment'));

    }).catch((error) => {
      // console.log(error)
      let message
      // console.log(error.response)
      if (typeof error.response == 'undefined' || error.response.data == '') {
        message = 'Diçka nuk funksionoi. Ju lutem provoni perseri me vone.';
      } else if (error.response.status == 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('hashedMsisdn');
        localStorage.removeItem('phone');
        dispatch(push('/'));
        return;
      } else {

        message = error.response.data.message;
      }

      dispatch({
        type: PAYMENT_FAIL,
        error: message
      })

    })

  }
}