import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { translate } from '../../helpers';
import classnames from 'classnames'
const styles = theme => ({
submit: {
    marginTop: theme.spacing.unit * 3,
    background: '#666666',
    color: '#363636',
    borderRadius: 0,
    padding: '11px 37px',
    lineHeight: '27px',
    width: '100%'
},
payment:{
    background:'#afafaf',
    fontSize: '16px',
    textTransform: 'none',
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
        width: '100%'
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '20px',
    },
    // [theme.breakpoints.up('md')]: {
    //     fontSize: '20px',
    // },
}

});

class CancelButton extends Component{

    render(){

        const { classes, handleCancel, config,value } = this.props;
        
        var cancel = value ? value : translate('login_tx_cancel', config); 
        

        return (
           <Button
            //   fullWidth
              variant="contained"
              color="primary"
              className={classnames(classes.submit,this.props.payment && classes.payment)}
              onClick={handleCancel}
            >
                {cancel}
            </Button>
        );
    }
}


export default withStyles(styles)(CancelButton);