import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Nav, MenuItem, Navbar, NavDropdown } from "react-bootstrap";
import { push } from "connected-react-router";
import { getConfig } from "../../actions";
import { connect } from "react-redux";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { translate } from "../../helpers";
import SmallMenu from "./SmallMenu";
import ArrowIcon from "./ArrowIcon";
import { RESET_LOGIN } from "../../actions/user";

const styles = (theme) => ({
  navbar: {
    background: "#fff",
    borderColor: "#fff",
    margin: 0,
    borderBottom: "1px solid rgba(102,102,102,0.5)",
    transition: "all .1s",
  },
  logo: {
    width: "48px",
    height: "48px",
    padding: 0,
    margin: "12px 10px 12px 0",
    [theme.breakpoints.down("sm")]: {
      width: "32px",
      height: "32px",
      margin: "8px 5px 5px 10px",
    },
  },
  menu: {
    margin: "0px 10px 0px 0",
  },
  submenuItem: {
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  submenu: {
    minWidth: "100px",
  },
  headerMain: {
    [theme.breakpoints.down("sm")]: {
      height: "50px!important",
    },
  },
  signout: {
    float: "right",
    padding: "10px",
    marginTop: "13px",
    color: "#4a4d4e",
    fontWeight: "bold",
    fontSize: "17px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center",
      fontSize: "18px",
    },
  },
  arrowIcon: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    float: "right",
    color: "#e52400",
    fontSize: "40px",
    marginTop: "-10px",
  },
  heading: {
    padding: "0px 10px!important",
    fontSize: "20px",
    fontFamily: "VodafoneRegular",
    color: "#333",
    width: "100%",
    "&:focus": {
      textDecoration: "none",
      color: "#333",
    },
    "&:hover": {
      textDecoration: "none",
      color: "#333",
    },
  },
  mobileSummary: {
    padding: 0,
    marginTop: "10px",
  },
  mobilePanel: {
    boxShadow: "none",
    margin: "0!important",
    "&:before": {
      opacity: 0,
    },
    background: "transparent",
  },
  mobileList: {
    listStyle: "none",
    padding: 0,
    width: "100%",
  },
  mobileDetails: {
    padding: "0 20px",
  },
  linkIcon: {
    float: "right",
    color: "#e60000",
  },
  expandedMenu: {
    color: "#e60000",
    minHeight: "35px!important",
  },
  expandedTitle: {
    margin: "7px 0 11px!important",
  },
  staticMenu: {
    backgroundColor: "rgb(102, 102, 102)",
    color: "white",
    marginTop: "60px",
    padding: "13px 10px",
    fontSize: "20px",
    marginBottom: "20px",
  },
  staticItem: {
    color: "#fff",
    "& :hover": {
      color: "#fff",
      textDecoration: "none",
    },
  },
  staticIcon: {
    float: "right",
    paddingRight: "10px",
  },
});

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, menu: "personal" };
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(getConfig());

    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = (event) => {
    let scrollTop = window.pageYOffset;

    if (scrollTop > 30) {
      this.setState({
        margin: "menuWithoutMargin",
      });
    } else {
      this.setState({
        margin: "menuWithMargin",
      });
    }
  };

  handleOpen = (menu) => {
    this.setState({
      [menu]: true,
    });
  };

  handleClose = (menu) => {
    this.setState({
      [menu]: false,
    });
  };
  signOut = () => {
    const { dispatch } = this.props;
    localStorage.removeItem("token");
    localStorage.removeItem("hashedMsisdn");
    localStorage.removeItem("phone");
    localStorage.removeItem("IDLogin");
    dispatch({
      type: RESET_LOGIN,
    });
    dispatch(push("/"));
  };

  handleMenuChange = (menu) => {
    this.setState({ menu: menu });
  };
  render() {
    const { classes, menu, config } = this.props;

    var logout_txt = translate("dilni", config);

    var globalMenu = [];
    if (this.state.menu == "personal" && typeof menu.personal != "undefined") {
      globalMenu = menu.personal;
    }

    if (this.state.menu == "business" && typeof menu.business != "undefined") {
      globalMenu = menu.business;
    }

    globalMenu = globalMenu.filter((item) => {
      return item.name != "" && item.key != "" && item.key != null;
    });

    const getMenu = globalMenu.map((item) => {
      let key = item.key;

      if (typeof item.subItems == "undefined") {
        item.subItems = [];
      }

      return (
        <NavDropdown
          title={item.name}
          id={item.key}
          onMouseEnter={() => this.handleOpen(key)}
          onMouseLeave={() => this.handleClose(key)}
          open={this.state[key]}
          className={classes.submenu}
          noCaret
          href={item.text}
          key={item.key}
        >
          {item.subItems.map((subItem) => {
            return (
              <MenuItem
                key={subItem.key}
                href={subItem.text}
                className={classes.submenuItem}
              >
                {subItem.value}{" "}
              </MenuItem>
            );
          })}
        </NavDropdown>
      );
    });

    const getMobileMenu = globalMenu.map((item) => {
      var itemLink = "#";
      var iconType = "";
      if (typeof item.subItems == "undefined") {
        item.subItems = [];
        itemLink = item.text;
        iconType = "secondaryArrow";
      }
      return (
        <ExpansionPanel className={classes.mobilePanel} key={item.menu}>
          <ExpansionPanelSummary
            classes={{
              expanded: classes.expandedMenu,
              content: classes.expandedTitle,
            }}
            className={classes.mobileSummary}
            expandIcon={<ArrowIcon type={iconType} />}
          >
            <a href={itemLink} className={classes.heading}>
              {" "}
              {item.name}{" "}
            </a>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.mobileDetails}>
            <ul className={classes.mobileList}>
              {item.subItems.map((subItem) => {
                return (
                  <li key={subItem.key}>
                    <a className={classes.submenuItem} href={subItem.text}>
                      {subItem.value}
                      <span className={classes.linkIcon}>
                        <ArrowIcon type="secondaryArrow" />
                      </span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });

    const getMobileHeadMenu = () => {
      if (this.state.menu == "personal") {
        return (
          <div
            onClick={() => this.handleMenuChange("business")}
            className={classes.staticMenu}
          >
            <a className={classes.staticItem} href="#">
              <span>Biznes</span>
              <span className={classes.staticIcon}>
                {" "}
                <ArrowIcon type="secondaryArrow" color="#fff" />{" "}
              </span>
            </a>
          </div>
        );
      } else {
        return (
          <div
            onClick={() => this.handleMenuChange("personal")}
            className={classes.staticMenu}
          >
            <a className={classes.staticItem} href="#">
              <span>Personal</span>
              <span className={classes.staticIcon}>
                {" "}
                <ArrowIcon type="secondaryArrow" color="#fff" />{" "}
              </span>
            </a>
          </div>
        );
      }
    };

    return (
      <div>
        <SmallMenu
          handleMenuChange={this.handleMenuChange}
          menu={this.state.menu}
        />
        <div className={classes.headerMain} style={{ height: "73px" }}>
          <style type="text/css">
            {`
					    ul.navbar-nav > li > a {
				            padding: 25px 20px 25px;
				            color: #333;
				            border-bottom: 3px solid #fff!important;
					    }
					    ul.navbar-nav > li > a:hover, .navbar-default .navbar-nav>.open>a {
							background: #fff!important;
							color: #e60000!important;
							border-bottom: 3px solid #e60000!important;
					    }
					    ul.navbar-nav li a{
					    	font-size: 24px;
					    	font-family: 'VodafoneLight';
					    	color: #333!important;
					    }
					    ul.navbar-nav ul.dropdown-menu{
						  width: 100%;
						  background: rgba(255, 255, 255, 0.85);
						  box-shadow: none;
						  border-radius: 0;
						  padding: 0px;
					      padding-left: 75px;
					      top: -100%;
					      visibility: hidden;
					      opacity: 0;
					      display: block;
					      z-index: -1;
					      transition: all .1s ease .15s;
						}

						ul.navbar-nav .open ul.dropdown-menu{
					      top: 100%;
				          visibility: visible;
				          opacity: 1;
				          transition: all .3s ease .3s;

						}

						ul.navbar-nav ul.dropdown-menu li:first-child{
						    margin-left: calc((100vw - 1200px) / 2 );
						}
						.dropdown-menu>li>a{
							background: transparent;
							padding: 13px;
						}

						.dropdown-menu>li>a:hover{
							background: #fff;
							color: #e60000;
						}
						li.dropdown{
							position: static;
						}

						.menuWithoutMargin{
							margin-top: 0;
						}

						.menuWithMargin{
							margin-top: 30px;
							z-index: 2;
						}

						.navbar-toggle.collapsed{
							border: none;
						    background: none;
						}

						.navbar-toggle.collapsed:hover{
							background: none;
						}
						
						.navbar-toggle.collapsed .icon-bar{
							background: #4a4d4e;
						}

						.navbar-brand .navbar-toggle .icon-bar{
							background: #e60000;
						}

						.navbar-toggle:not(.collapsed){
							background-color: #e60000!important;
							border-radius: 0px;
							padding: 9px;
							border-color: #fff;
						}

						.navbar-toggle:not(.collapsed) .icon-bar{
							background: #fff!important;
						}

						.navbar-collapse.collapse{
							background: rgba(255, 255, 255, 0.96);
							border: none;
							height: calc(100vh - 50px);
						}

						@media(max-width: 768px){
							ul.navbar-nav li a, .navbar-nav .open .dropdown-menu>li>a{
								padding: 15px 10px;
								font-size: 20px;
							    font-family: VodafoneRegular;
							    padding-right: 0px;
							}

						    ul.navbar-nav ul.dropdown-menu{
						      padding-left: 20px;
							}
							ul.navbar-nav ul.dropdown-menu li:first-child{
							    margin-left: 0;
							}
							
							ul.navbar-nav > li > a:hover, .navbar-default .navbar-nav>.open>a{
								border-bottom: none!important;
							}
							.navbar.navbar-default{
								background: transparent;
								border: none;
							}
							.navbar-collapse{
								background: #fff;
							    margin-left: 30px!important;
							    max-height: calc(100vh - 50px)!important;
							}
							.navbar-header{
								background: #fff;
							}

							ul.navbar-nav ul.dropdown-menu{
								display: none;
							    opacity: 1;
							    visibility: visible;
							    z-index: 1;
							    top: 100%;
							}

							ul.navbar-nav .open ul.dropdown-menu{
					          display: block;
							}

						}
					    `}
          </style>
          <Navbar
            className={[
              classes.navbar,
              "navbar-fixed-top",
              this.state.margin,
            ].join(" ")}
            collapseOnSelect
          >
            <Navbar.Header>
              <Navbar.Brand>
                <img className={classes.logo} src="/logo.png" alt="" />
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav className={["hidden-sm hidden-xs", classes.menu].join(" ")}>
                {getMenu}
              </Nav>
              <Nav className={["hidden-lg hidden-md", classes.menu].join(" ")}>
                <div className={classes.mainMobile}>
                  {getMobileMenu}
                  {getMobileHeadMenu()}
                </div>
              </Nav>
              <a
                onClick={() => this.signOut()}
                className={classes.signout}
                href="#"
              >
                Dilni
              </a>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { menu, config } = state.config;

  return { menu, config };
};

export default connect(mapStateToProps)(withStyles(styles)(Menu));
