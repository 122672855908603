import { combineReducers } from "redux";
import {
  PIN_SENT,
  PIN_ERROR,
  PIN_RECEIVED,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  FORGOT_PASSWORD,
  PASSWORD_PIN_RECEIVED,
  FORGOT_PASSWORD_NUMBER,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_LOGIN,
  RESET_REGISTER,
  RESET_FORGOT,
  RESET_PIN,
  CHECK_SEND,
  CHECK_RECEIVED,
  CONFIRM_SEND,
  CONFIRM_RECEIVED,
  IDAAS_CHECK_RECEIVED,
  IDAAS_CHECK_SEND,
  IDAAS_LOGIN_ERROR,
  IDAAS_LOGIN_SUCCESS,
} from "../actions/user";

import { AUTH_ERROR } from "../actions";

import { user, documents, config } from "./accounts";

const initialState = {
  userData: {},
  isFetching: false,
  isError: false,
  getPin: {
    pinSent: false,
    error: false,
    message: "",
    confirming: false,
  },
  register: {
    error: false,
    message: "",
    registered: false,
  },
  login: {
    status: undefined,
    error: false,
    message: "",
    authenticated: false,
    token: "",
    checking: false,
  },
  idassLogin: {
    error: false,
    message: "",
    authenticated: false,
    token: "",
    checking: false,
  },
  forgotPassword: {
    error: false,
    message: "",
    forgot: false,
    pin: false,
    status: "",
    number: "",
  },
};

const getPin = (state = initialState.getPin, action) => {
  switch (action.type) {
    case PIN_SENT:
      return {
        ...state,
        pinSent: true,
        error: false,
        message: "",
      };

    case PIN_ERROR:
      return {
        ...state,
        pinSent: false,
        error: true,
        message: action.message,
      };
    case PIN_RECEIVED:
      return {
        ...state,
        pinSent: false,
        error: false,
        message: "",
      };

    case RESET_PIN:
      return {
        ...state,
        pinSent: false,
        error: false,
        message: "",
        confirming: false,
      };
    case CONFIRM_SEND:
      return {
        ...state,
        confirming: true,
      };

    case CONFIRM_RECEIVED:
      return {
        ...state,
        confirming: false,
      };

    default:
      return state;
  }
};

const register = (state = initialState.register, action) => {
  switch (action.type) {
    case REGISTER_ERROR:
      return {
        ...state,
        error: true,
        message: action.message,
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        error: false,
        message: "Your account was created successfully!",
        registered: true,
      };

    case RESET_REGISTER:
      return {
        ...state,
        error: false,
        message: "",
        registered: false,
      };

    default:
      return state;
  }
};

const login = (state = initialState.login, action) => {
  switch (action.type) {
    case LOGIN_ERROR:
      return {
        ...state,
        error: true,
        message: action.message,
        status: action.status || undefined,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        error: false,
        token: action.data.token,
      };
    case RESET_LOGIN:
      return {
        ...state,
        error: false,
        message: "",
        authenticated: false,
        token: "",
      };

    case AUTH_ERROR:
      return {
        ...state,
        authenticated: false,
        token: "",
      };

    case CHECK_SEND:
      return {
        ...state,
        checking: true,
      };
    case CHECK_RECEIVED:
      return {
        ...state,
        checking: false,
      };
    default:
      return state;
  }
};

const idassLogin = (state = initialState.idassLogin, action) => {
  switch (action.type) {
    case IDAAS_LOGIN_ERROR:
      return {
        ...state,
        error: true,
        message: action.message,
      };

    case IDAAS_LOGIN_SUCCESS:
      return {
        ...state,
        error: false,
        token: action.data.token,
      };

    case IDAAS_CHECK_SEND:
      return {
        ...state,
        checking: true,
      };
    case IDAAS_CHECK_RECEIVED:
      return {
        ...state,
        checking: false,
      };
    default:
      return state;
  }
};

const forgotPassword = (state = initialState.forgotPassword, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgot: true,
      };

    case PASSWORD_PIN_RECEIVED:
      return {
        ...state,
        pin: action.pin,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        message: action.message,
        status: "success",
        error: false,
        forgot: false,
        pin: false,
        number: "",
      };

    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        message: action.message,
        status: "error",
        error: true,
      };

    case FORGOT_PASSWORD_NUMBER:
      return {
        ...state,
        number: action.number,
      };

    case RESET_FORGOT:
      return {
        ...state,
        error: false,
        message: "",
        forgot: false,
        pin: false,
        status: "",
        number: "",
      };

    default:
      return state;
  }
};

export default combineReducers({
  getPin,
  register,
  login,
  forgotPassword,
  user,
  documents,
  config,
  idassLogin,
});
