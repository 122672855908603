import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Error from "./../../components/Error";
import { translate } from "../../helpers";
import {
  getClientAccounts,
  getAccountDocuments,
  downloadFile,
} from "../../actions";
import { getPaymentForm } from "../../actions/payment/payment.action";
//import Invoices from './Invoices';
import CloudDownload from "@material-ui/icons/CloudDownload";
import Loading from "../../components/Loading";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowIcon from "./ArrowIcon";
import Button from "@material-ui/core/Button";
import GreenTickImg from "./../../greenTick.png";
import ArrowImg from "./../../arrow-1.svg";
import Grid from "./../../components/VfGrid";

const styles = (theme) => ({
  root: {
    maxWidth: "1200px",
    margin: "0 auto",
    paddding: "0 10px",
    marginTop: "40px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 12,
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '66.66%',
    flexShrink: 0,
    padding: "34px 0px",
    margin: 0,
    fontSize: "22px",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      // fontSize: '18px',
      fontSize: "12px",
      padding: "19px 0px",
      paddingRight: "12px!important",
    },
  },
  headingTotal: {
    padding: "0px 40px",
    paddingTop: 30,
    fontSize: 22,
    fontFamily: "VodafoneBold",
    marginRight: 128,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      fontSize: "16px",
      padding: "0px",
      paddingTop: 15,
      paddingRight: "10px!important",
    },
  },
  subHeadingTotal: {
    padding: "0px 40px",
    paddingBottom: 30,
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      padding: "0px",
      paddingBottom: 15,
      paddingRight: "10px!important",
    },
  },
  monthName: {
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  amountValueText: {
    fontSize: "28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  headingDoc: {
    padding: "0",
  },
  subHeadingDoc: {
    padding: "0",
    // paddingBottom: 30,
  },
  panelTotal: {
    display: "block",
    margin: "35px 0px",
    marginBottom: 48,
    [theme.breakpoints.down("sm")]: {
      margin: "24px 0px",
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  panel: {
    background: "#fff",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    margin: "0 0 10px",
    borderRadius: "0!important",
  },
  panelDetails: {
    borderTop: "1px solid #E0E0E0",
    margin: "0px 40px",
    padding: 0,
    display: "block",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 12px",
    },
  },
  expandIcon: {
    fontSize: "45px",
    color: "#e60000",
  },
  progress: {
    // color: '#333',
    position: "absolute",
  },
  download: {
    // padding: '10px 0px',
    // borderTop: '1px solid #827474',
    paddingBottom: 24,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 12,
    },
    // borderBottom: '1px solid #E0E0E0',
  },
  downloadSection: {
    display: "inline-block",
    width: "15%",
    textAlign: "right",
    fontSize: "",
  },
  file: {
    display: "inline-block",
    width: "85%",
  },
  downloadIcon: {
    cursor: "pointer",
    color: "#333",
    stroke: "currentColor",
    strokeWidth: ".4em",
    width: "39.89px",
    height: "39.89px",
    marginRight: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "24px",
      height: "24px",
    },
  },
  description: {
    marginBottom: "10px",
    fontSize: "17px",
    color: "#000",
  },
  fileName: {
    fontWeight: "bold",
    fontSize: 18,
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  fileDate: {
    fontSize: "18px",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  fileTitle: {
    marginBottom: "30px",
    marginTop: "35px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  document: {
    marginTop: "32px",
    paddingLeft: 24,
    borderBottom: "1px solid #E0E0E0",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 12,
      marginTop: 20,
    },
  },
  error: {
    textAlign: "center",
    fontSize: "20px",
  },
  paymentError: {
    marginTop: "16px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "28px",
    },
  },
  cursorAuto: {
    cursor: "default",
    pointerEvents: "none",
  },
  submit: {
    backgroundColor: "#e60000",
    borderColor: "#e60000",
    color: "#fff",
    borderRadius: 0,
    fontSize: "20px",
    textTransform: "capitalize",
    padding: "11px 37px",
    lineHeight: "27px",
    opacity: "1!important",
    marginTop: 0,
    [theme.breakpoints.down("xs")]: {
      padding: "11px 25px",
    },
    ["@media (max-width:350px)"]: {
      // eslint-disable-line no-useless-computed-key
      padding: "7px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  submitDoc: {
    boxShadow: "none",
    padding: "14px 52px",
    [theme.breakpoints.down("sm")]: {
      padding: "9px 26px",
    },
  },
  paidImg: {
    width: "16px",
    height: "16px",
    marginRight: 8,
    [theme.breakpoints.down("xs")]: {
      // width: '16px',
      // height: '16px',
      // marginRight: 8,
    },
  },
  paidLabel: {
    fontSize: "18px",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  padding0: {
    padding: 0,
  },
  TopFix: {
    top: "39%",
    right: -12,
    pointerEvents: "auto",
    [theme.breakpoints.down("sm")]: {
      // top: '33%',
    },
  },
  bonusGb: {
    color: "#e60000",
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  paidBonusGb: {
    color: "#009900",
  },
  accountNameContainer: {
    margin: "0 40px",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      margin: "0 16px",
      minHeight: "56px !important",
    },
  },
  documentNameIcon: {
    right: 0,
    width: 39,
    height: 39,
  },
  amountContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  documentNameContent: {
    margin: 0,
  },
  documentNameExpanded: {
    margin: "14px 0px !important",
    [theme.breakpoints.down("sm")]: {
      margin: "0px !important",
    },
  },
  arrowImg: {
    width: 40,
    height: 40,
    [theme.breakpoints.down("sm")]: {
      width: 32,
      height: 32,
    },
  },
});

class Panels extends React.Component {
  state = {
    expanded: null,
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getClientAccounts());
  }

  getDocuments = (accountid) => {
    const { dispatch, documents } = this.props;

    if (typeof documents[accountid] == "undefined") {
      dispatch(getAccountDocuments(accountid));
    }
  };

  handleChange = (panel) => (event, expanded) => {
    this.getDocuments(panel);

    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleFileDownload = (account, document, name) => {
    const { dispatch } = this.props;

    dispatch(downloadFile(account, document, name));
  };

  render() {
    let { classes, user, documents, dispatch, config } = this.props;

    const { expanded } = this.state;

    if (user.loading) {
      return (
        <div className={classes.root}>
          <Loading />
        </div>
      );
    }

    if (user.error) {
      return <div className={classes.error}>{user.message}</div>;
    }

    const accountDocuments = (account) => {
      // documents = JSON.parse('{"loading":false,"downloading":false,"D4A890EBDABBE01E0102DA6F54A60258":{"documents":[{"_links":null,"id":[{"value":"A2DE53765CFD7CD8E05357BEE9C3AFB3","schemeID":null,"schemeName":"Document ID","schemeAgencyName":"Vodafone"}],"name":"Janar 2020","desc":"Document Bill","type":"CustomerBill.","category":[{"value":"zip","listHierarchyId":"Document_Category"}],"status":"Success","version":null,"created":{"value":"2020-02-03 23:45:59","format":null},"createdBy":null,"lastModified":null,"lastModifiedBy":null,"validityPeriod":{"fromDate":{"dateString":null,"date":"01/01/2020"},"toDate":{"dateString":null,"date":"31/01/2020"}},"lastActivityDate":null,"details":null,"relatedCustomerBill":null,"roles":null,"relatedParty":null,"parts":{"amountsDue":null,"appliedBillingRate":null,"billingAccount":null,"customerAccount":null,"billingCycle":null,"billFormat":null,"document":[{"_links":null,"id":null,"name":null,"desc":null,"type":null,"category":null,"status":null,"binaryObject":{"value":null,"format":"base64","mimeCode":null,"encodingCode":null,"characterSetCode":null,"uri":"https://pkiv01-billingservice-al.myvdf.aws.cps.vodafone.com/auth/customerBillAPI/v2/customerBill/D4A890EBDABBE01E0102DA6F54A60258/document/A2DE53765CFD7CD8E05357BEE9C3AFB3","fileName":"Janar 2020 221951822292.zip"},"size":{"value":"Bytes","unitCode":"1245"},"reference":{"id":[{"value":"355698933127","schemeID":null,"schemeName":"Reference Id","schemeAgencyName":"Vodafone"}]}}],"dueDateShiftPeriod":null,"financialCharge":null,"lineItem":null,"note":null,"salesOrder":null,"specification":null,"statusHistory":null,"subscription":null,"taxeDue":null,"payment":null}}]}}')

      var invoices = "";
      if (documents.loading) {
        return <Loading />;
      }

      if (
        typeof documents[account] != "undefined" &&
        documents[account].documents.length == 0
      ) {
        return <div>Fatura nuk eshte gati per perdorimin tuaj.</div>;
      }

      if (typeof documents[account] != "undefined") {
        invoices = documents[account].documents.map((document, i) => {
          let from_date = "";
          try {
            from_date = document.validityPeriod.fromDate.date;
          } catch {}

          let to_date = "";
          try {
            to_date = document.validityPeriod.toDate.date;
          } catch {}

          let period = from_date + " - " + to_date;

          if (period == " - ") {
            period = "";
          }
          let { value, currencyID } = document.parts.amountsDue.amount;
          let billId = document.parts.document[0].id[0].value;
          let isPaid = document.status === "Paid";
          let { payment } = this.props;
          let isLoading = payment.loading && payment.billId === billId;

          return (
            <div className={classes.document}>
              <Typography
                className={[
                  classes.heading,
                  classes.headingTotal,
                  classes.headingDoc,
                  classes.monthName,
                ].join(" ")}
              >
                {document.name}
              </Typography>

              <ExpansionPanelSummary
                expandIcon={
                  isPaid ? (
                    <>
                      <img
                        src={GreenTickImg}
                        alt=""
                        className={classes.paidImg}
                      />
                      <span className={classes.paidLabel}>
                        {translate("ebillPayment_paid_label", config)}
                      </span>
                    </>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      className={[classes.submit, classes.submitDoc].join(" ")}
                      onClick={() =>
                        dispatch(
                          getPaymentForm(account, billId, value, currencyID)
                        )
                      }
                    >
                      {isLoading && (
                        <CircularProgress
                          size={20}
                          className={classes.progress}
                        />
                      )}
                      <span
                        style={{
                          color: isLoading ? "transparent" : "inherit",
                        }}
                      >
                        {translate("ebillPayment_pay_now_button", config)}
                      </span>
                    </Button>
                  )
                }
                classes={{
                  content: [classes.panelTotal, classes.cursorAuto].join(" "),
                  expandIcon: [
                    isPaid && classes.cursorAuto,
                    classes.TopFix,
                  ].join(" "),
                }}
                className={[classes.cursorAuto, classes.padding0].join(" ")}
              >
                <Grid
                  container
                  alignItems="center"
                  className={classes.amountContainer}
                >
                  <Typography
                    className={[
                      classes.heading,
                      classes.headingTotal,
                      classes.headingDoc,
                    ].join(" ")}
                  >
                    {translate("ebillPayment_monthly_bill", config)}
                  </Typography>
                  <Typography
                    className={[
                      classes.heading,
                      classes.subHeadingTotal,
                      classes.headingDoc,
                    ].join(" ")}
                  >
                    <span className={classes.amountValueText}>{value}</span>{" "}
                    {currencyID}
                  </Typography>
                </Grid>
                <Typography
                  className={[
                    classes.subHeadingDoc,
                    classes.bonusGb,
                    isPaid && classes.paidBonusGb,
                  ].join(" ")}
                >
                  {document.parts.document[0].desc}
                </Typography>
              </ExpansionPanelSummary>

              {/* <Typography className={classes.description} gutterBottom>
                {document.description}
              </Typography> */}

              <div className={classes.download}>
                <div className={classes.file}>
                  <div className={classes.fileName}>
                    {document.parts.document[0].binaryObject.fileName}
                  </div>
                  <span className={classes.fileDate}>{period}</span>
                </div>
                <span className={classes.downloadSection}>
                  {documents.downloading == document.id[0].value ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    <span>
                      <svg
                        onClick={() =>
                          this.handleFileDownload(
                            account,
                            document.id[0].value,
                            document.parts.document[0].binaryObject.fileName
                          )
                        }
                        className={classes.downloadIcon}
                        id="download"
                        data-name="ICON"
                        viewBox="0 0 192 192"
                      >
                        <g
                          id="32edccff-519c-45db-948a-34c5c6256ba8"
                          data-name="Download"
                        >
                          <path
                            d="M172,132v20a20,20,0,0,1-20,20H40a20,20,0,0,1-20-20V132"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="8"
                          ></path>
                          <g
                            id="d9b274fb-d981-4bc9-86ab-8969785b04f4"
                            data-name="Cloud_download"
                          >
                            <line
                              x1="96"
                              y1="52"
                              x2="96"
                              y2="148"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="8"
                            ></line>
                            <polyline
                              points="122 120 96 148 70 120"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="8"
                            ></polyline>
                          </g>
                        </g>
                      </svg>
                      {/* <span style={{ display: 'block' }}>Shkarko</span> */}
                    </span>
                  )}
                </span>
              </div>
            </div>
          );
        });
      }

      return invoices;
    };

    const panels = user.accounts.map((account) => {
      return (
        <ExpansionPanel
          key={account.id[0].value}
          className={classes.panel}
          expanded={expanded === account.id[0].value}
          onChange={this.handleChange(account.id[0].value)}
        >
          <ExpansionPanelSummary
            expandIcon={
              <img src={ArrowImg} alt="arrow" className={classes.arrowImg} />
            }
            className={classes.accountNameContainer}
            classes={{
              expandIcon: [classes.padding0, classes.documentNameIcon].join(
                " "
              ),
              content: [
                classes.documentNameContent,
                expanded && classes.documentNameExpanded,
              ].join(" "),
            }}
          >
            <Typography className={classes.heading}>{account.name}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.panelDetails}>
            {accountDocuments(account.id[0].value)}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
    let { error, message: errorMessage } = this.props.payment;
    // let error=true
    // let errorMessage = "This bill is already paid, or being processed, or being calclauted!"
    // let isPaid = false
    return (
      <>
        {/* {error && <div className={[classes.error, classes.paymentError].join(' ')}>{errorMessage}</div>} */}
        {error && (
          <div className={classes.paymentError}>
            <Error error={errorMessage} />
          </div>
        )}
        <div className={classes.root}>{panels}</div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, documents, config } = state;
  const { payment } = user;
  return { user, documents, payment, config };
};

export default connect(mapStateToProps)(withStyles(styles)(Panels));
