import axios from "axios";
import qs from "qs";
import { push } from "connected-react-router";

export const PIN_SENT = "PIN_SENT";
export const PIN_ERROR = "PIN_ERROR";
export const PIN_RECEIVED = "PIN_RECEIVED";

export const sendPin = (phone) => {
  return (dispatch) => {
    if (phone.startsWith("06")) {
      phone = phone.replace("06", "3556");
    }
    var headers = {
      "Content-Type": "application/json",
      "X-Source-System": "selfcare",
      "X-Source-Operator": "myvodafone",
      "Accept-Language": "al",
    };

    var data = {
      details: {
        "credential-type": {
          $: "pin",
          "@list-agency-name": "Vodafone",
        },
        "username-text": {
          $: phone,
        },
      },
    };

    data = JSON.stringify(data);

    var url =
      "https://api-mobile-ci-vfal.vodafone.com/enterprise-resources/public/identity/change-authentication-credentials/" +
      phone;
    //var url = 'http://localhost:3030/pin';
    axios
      .post(url, data, {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: PIN_SENT,
        });
        dispatch({
          type: CHECK_RECEIVED,
        });
      })
      .catch((error) => {
        if (typeof error.response == "undefined" || error.response.data == "") {
          var message =
            "Diçka nuk funksionoi. Ju lutem provoni perseri me vone.";
        } else {
          var message = error.response.data.message;
        }

        dispatch({
          type: PIN_ERROR,
          message: message,
        });

        dispatch({
          type: CHECK_RECEIVED,
        });
      });
  };
};

export const SEND_REGISTER = "SEND_REGISTER";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const register = (user) => {
  return (dispatch) => {
    const { phone, password, pin } = user;

    var number = phone;

    if (number.startsWith("06")) {
      number = number.replace("06", "3556");
    }

    var headers = {
      "Content-Type": "application/json",
      "X-Source-System": "selfcare",
      "X-Source-Operator": "myvodafone",
      "X-Source-CountryCode": "al",
      "Accept-Language": "al",
    };

    var userInfo = {
      parts: {
        credentials: [
          {
            "password-text": {
              $: password,
              "@language-id": "string",
              "@language-locale-id": "string",
            },
            pins: [
              {
                $: pin,
              },
            ],
            type: {
              $: "password",
              "@language-id": "string",
              "@list-agency-id": "string",
              "@list-agency-name": "string",
              "@list-id": "string",
              "@list-name": "string",
              "@list-scheme-uri": "string",
              "@list-uri": "string",
              "@list-version-id": "string",
              "@name": "string",
            },
            "username-text": {
              $: number,
              "@language-id": "string",
              "@language-locale-id": "string",
            },
          },
        ],
      },
    };

    userInfo = JSON.stringify(userInfo);

    var url =
      "https://api-mobile-ci-vfal.vodafone.com/enterprise-resources/public/identity/customer-system-user-accounts";
    //var url = 'http://localhost:3030/register';
    axios
      .post(url, userInfo, {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });

        dispatch({
          type: PIN_RECEIVED,
        });
      })
      .catch((error) => {
        dispatch({
          type: PIN_RECEIVED,
        });

        if (typeof error.response == "undefined" || error.response.data == "") {
          var message =
            "Diçka nuk funksionoi. Ju lutem provoni perseri me vone.";
        } else {
          var message = error.response.data.message;
        }

        dispatch({
          type: REGISTER_ERROR,
          message: message,
        });
      });
  };
};

export const SEND_LOGIN = "SEND_LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const CONFIRM_SEND = "CONFIRM_SEND";
export const CONFIRM_RECEIVED = "CONFIRM_RECEIVED";

export const login = (user) => {
  return (dispatch) => {
    const { phone, password, pin } = user;

    var number = phone;

    if (number.startsWith("06")) {
      number = number.replace("06", "3556");
    }

    dispatch({
      type: CONFIRM_SEND,
    });

    var headers = {
      "Content-Type": "application/json",
      "vf-source": " ebill",
      "vf-operator": " ebill",
      "Accept-Language": " al",
      "vf-country-code": " al",
    };

    var userInfo = {
      name: "Check Authentication Credential",
      desc: "Check Authentication Credential",
      type: "pin",
      category: [
        {
          listHierarchyId: "1",
          value: "password",
        },
      ],
      details: {
        passwordText: password,
        passwordHintText: "password",
        usernameText: number,
        pin: [
          {
            typeCode: "token",
            status: "Active",
            value: pin,
          },
        ],
        credentialType: "password",
      },
      parts: {
        specification: {
          id: [
            {
              schemeAgencyName: "Vodafone",
              schemeName: "Specification ID",
              value: "123",
            },
          ],
          name: "Consumer Change Authentication Credential",
          desc: "Consumer Change Authentication Credential",
          type: "token",
          category: [
            {
              listHierarchyId: "1",
              value: "token",
            },
          ],
          characteristicsValue: [
            {
              characteristicName: "client_id",
              value:
                "ar7e7uylptrigtb3tfa0woq1bcesn0o4hlov5cm0vj823pb2ubsw58n6qsokiie5",
              creationTime: {
                value: "2015-04-01T00:00:00",
              },
              validityPeriod: {
                fromDate: {
                  date: "2015-04-01",
                },
                toDate: {
                  date: "2015-04-01",
                },
              },
            },
            {
              characteristicName: "client_secret",
              value:
                "vcd4t0jhi1tdlvi23h4633bj9mhw03cllfv45lstcbdnclvl85x1yzrdry0hri8i",
              creationTime: {
                value: "2015-04-01T00:00:00",
              },
              validityPeriod: {
                fromDate: {
                  date: "2015-04-01",
                },
                toDate: {
                  date: "2015-04-01",
                },
              },
            },
            {
              characteristicName: "custom_key",
              value: "client_secret_value",
              creationTime: {
                value: "2015-04-01T00:00:00",
              },
              validityPeriod: {
                fromDate: {
                  date: "2015-04-01",
                },
                toDate: {
                  date: "2015-04-01",
                },
              },
            },
          ],
        },
      },
    };

    userInfo = JSON.stringify(userInfo);
    var url =
      "https://api-mobile-ci-vfal.vodafone.com/public/changeAuthenticationCredentialAPI/v2/authenticationCredential/check";
    //var url = 'http://localhost:3030/register';
    axios
      .post(url, userInfo, {
        headers: headers,
      })
      .then((response) => {
        const characteristicsValue =
          response.data.parts.specification["characteristicsValue"];
        var token = characteristicsValue.find(
          (char) => char.characteristicName === "access_token"
        ).value;
        var hashedMsisdn = characteristicsValue.find(
          (char) => char.characteristicName === "hashed_msisdn"
        ).value;
        localStorage.setItem("token", token);
        localStorage.setItem("hashedMsisdn", hashedMsisdn);
        localStorage.setItem("phone", number);

        dispatch({
          type: PIN_RECEIVED,
        });

        dispatch({
          type: LOGIN_SUCCESS,
          data: {
            token: token,
          },
        });

        dispatch({
          type: CONFIRM_RECEIVED,
        });

        dispatch(push("/home"));
      })
      .catch((error) => {
        // dispatch({
        // 	type: PIN_RECEIVED
        // });

        // dispatch({
        // 	type: LOGIN_ERROR,
        // 	message: error.response.data.message,
        // });
        if (typeof error.response == "undefined" || error.response.data == "") {
          var message =
            "Diçka nuk funksionoi. Ju lutem provoni perseri me vone.";
        } else {
          var message = error.response.data.message;
        }

        dispatch({
          type: CONFIRM_RECEIVED,
        });

        dispatch({
          type: PIN_ERROR,
          message: message,
        });
      });
  };
};

export const IDAAS_CHECK_SEND = "IDAAS_CHECK_SEND";
export const IDAAS_CHECK_RECEIVED = "IDAAS_CHECK_RECEIVED";
export const preAuthorize = () => {
  return async (dispatch) => {
    const config = {
      method: "get",
      url: "https://api-mobile-ci-vfal.vodafone.com/OAuth2/v2/preAuthorize",
      headers: {
        "vf-source": "ebill",
        "Accept-Language": "al",
      },
    };
    try {
      dispatch({
        type: IDAAS_CHECK_SEND,
      });

      const response = await axios(config);
      const { state, redirect_url } = response.data;

      if (state && redirect_url) {
        return { state, redirect_url };
      }
    } catch (error) {
      if (typeof error.response == "undefined" || error.response.data == "") {
        var message = "Diçka nuk funksionoi. Ju lutem provoni perseri me vone.";
      } else {
        var message = error.response.data.message;
      }

      dispatch({
        type: IDAAS_CHECK_RECEIVED,
      });

      dispatch({
        type: LOGIN_ERROR,
        message: message,
      });
    }
  };
};

export const IDAAS_LOGIN_ERROR = "IDAAS_LOGIN_ERROR";
export const IDAAS_LOGIN_SUCCESS = "IDAAS_LOGIN_SUCCESS";

export const vodafoneIdLogin = (code, state) => {
  return async (dispatch) => {
    const data = qs.stringify({
      grant_type: "urn:ietf:params:oauth:grant-type:token-exchange",
      requested_token_type: "urn:ietf:params:oauth:token-type:access_token",
      subject_token: code,
      subject_token_type:
        "urn:vodafone:params:oauth:token-type:authorization_code",
      state: state,
    });

    const config = {
      method: "post",
      url: "https://api-mobile-ci-vfal.vodafone.com/OAuth2/v2/token",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      data: data,
    };

    if (state && code) {
      try {
        const response = await axios(config);
        const { access_token: token, user_info } = response.data;
        const hashedMsisdn = user_info.userAssets.find(
          (element) => element.assetType === "hashedMsisdn"
        ).id;

        if (token && user_info) {
          localStorage.setItem("token", token);
          localStorage.setItem("hashedMsisdn", hashedMsisdn);
          localStorage.setItem("phone", user_info.phone_number);

          dispatch({
            type: LOGIN_SUCCESS,
            data: {
              token: token,
            },
          });

          dispatch({
            type: CONFIRM_RECEIVED,
          });

          dispatch(push("/home"));
        }

        throw new Error(
          "Diçka nuk funksionoi. Ju lutem provoni perseri me vone."
        );
      } catch (error) {
        if (
          typeof error.response === "undefined" ||
          error.response.data == ""
        ) {
          var message =
            "Diçka nuk funksionoi. Ju lutem provoni perseri me vone.";
        } else {
          var message = error.response.data.error_description;
        }

        dispatch({
          type: IDAAS_CHECK_RECEIVED,
        });

        dispatch({
          type: LOGIN_ERROR,
          message: message,
          status: error.response.status,
        });
      }
    }
  };
};

export const CHECK_SEND = "CHECK_SEND";
export const CHECK_RECEIVED = "CHECK_RECEIVED";

export const checkLogin = (phone, password) => {
  return (dispatch) => {
    if (phone.startsWith("06")) {
      phone = phone.replace("06", "3556");
    }

    dispatch({
      type: CHECK_SEND,
    });

    var headers = {
      "Content-Type": "application/json",
      "vf-source": " ebill",
      "vf-operator": " ebill",
      "vf-country-code": "al",
      "Accept-Language": "al",
    };

    var userInfo = {
      name: "Check Authentication Credential",
      desc: "Check Authentication Credential",
      type: "password",
      category: [
        {
          listHierarchyId: "1",
          value: "password",
        },
      ],
      details: {
        passwordText: password,
        passwordHintText: "s.rogers",
        usernameText: phone,
        credentialType: "password",
      },
      parts: {
        specification: {
          id: [
            {
              schemeAgencyName: "Vodafone",
              schemeName: "Specification ID",
              value: "123",
            },
          ],
          name: "Consumer Change Authentication Credential",
          desc: "Consumer Change Authentication Credential",
          type: "token",
          category: [
            {
              listHierarchyId: "1",
              value: "token",
            },
          ],
          characteristicsValue: [
            {
              characteristicName: "client_id",
              value:
                "ar7e7uylptrigtb3tfa0woq1bcesn0o4hlov5cm0vj823pb2ubsw58n6qsokiie5",
              creationTime: {
                value: "2015-04-01T00:00:00",
              },
              validityPeriod: {
                fromDate: {
                  date: "2015-04-01",
                },
                toDate: {
                  date: "2015-04-01",
                },
              },
            },
            {
              characteristicName: "client_secret",
              value:
                "vcd4t0jhi1tdlvi23h4633bj9mhw03cllfv45lstcbdnclvl85x1yzrdry0hri8i",
              creationTime: {
                value: "2015-04-01T00:00:00",
              },
              validityPeriod: {
                fromDate: {
                  date: "2015-04-01",
                },
                toDate: {
                  date: "2015-04-01",
                },
              },
            },
            {
              characteristicName: "custom_key",
              value: "client_secret_value",
              creationTime: {
                value: "2015-04-01T00:00:00",
              },
              validityPeriod: {
                fromDate: {
                  date: "2015-04-01",
                },
                toDate: {
                  date: "2015-04-01",
                },
              },
            },
          ],
        },
      },
    };

    userInfo = JSON.stringify(userInfo);

    var url =
      "https://api-mobile-ci-vfal.vodafone.com/public/changeAuthenticationCredentialAPI/v2/authenticationCredential/check";
    //var url = 'http://localhost:3030/register';
    axios
      .post(url, userInfo, {
        headers: headers,
      })
      .then((response) => {
        dispatch(sendPin(phone));
      })
      .catch((error) => {
        if (typeof error.response == "undefined" || error.response.data == "") {
          var message =
            "Diçka nuk funksionoi. Ju lutem provoni perseri me vone.";
        } else {
          var message = error.response.data.message;
        }

        dispatch({
          type: CHECK_RECEIVED,
        });

        dispatch({
          type: LOGIN_ERROR,
          message: message,
        });
      });
  };
};

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const showForgotForm = () => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD,
    });
  };
};

export const FORGOT_PASSWORD_NUMBER = "FORGOT_PASSWORD_NUMBER";

export const forgotPasswordNumber = (number) => {
  return (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD_NUMBER,
      number: number,
    });
  };
};

export const PASSWORD_PIN_RECEIVED = "PASSWORD_PIN_RECEIVED";

export const passwordPinReceived = (pin) => {
  return (dispatch) => {
    dispatch({
      type: PASSWORD_PIN_RECEIVED,
      pin: pin,
    });
  };
};

export const RESET_LOGIN = "RESET_LOGIN";
export const RESET_REGISTER = "RESET_REGISTER";
export const RESET_FORGOT = "RESET_FORGOT";
export const RESET_PIN = "RESET_PIN";

export const cancelAction = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_LOGIN,
    });

    dispatch({
      type: RESET_REGISTER,
    });

    dispatch({
      type: RESET_FORGOT,
    });

    dispatch({
      type: RESET_PIN,
    });
  };
};

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const resetPassword = (phone, password, pin) => {
  return (dispatch) => {
    if (phone.startsWith("06")) {
      phone = phone.replace("06", "3556");
    }

    var headers = {
      "Content-Type": "application/json",
      "X-Source-System": "selfcare",
      "X-Source-Operator": "myvodafone",
      "Accept-Language": "al",
    };

    var data = {
      details: {
        "credential-type": {
          $: "password",
          "@list-agency-name": "Vodafone",
        },
        "password-text": {
          $: password,
          "@language-id": "string",
          "@language-locale-id": "string",
        },
        pins: [
          {
            $: pin,
          },
        ],
        "username-text": {
          $: phone,
          "@language-id": "string",
          "@language-locale-id": "string",
        },
      },
      parts: {
        specification: {
          "characteristics-value": [
            {
              "@characteristic-name": "q1_question",
              description: {
                $: "string",
                "@language-id": "string",
                "@language-locale-id": "string",
              },
              type: {
                $: "Question",
                "@language-id": "string",
                "@list-agency-id": "string",
                "@list-agency-name": "string",
                "@list-id": "string",
                "@list-name": "string",
                "@list-scheme-uri": "string",
                "@list-uri": "string",
                "@list-version-id": "string",
                "@name": "string",
              },
              value: {
                $: "What is your dog first name?",
                "@language-id": "string",
                "@language-locale-id": "string",
              },
            },
            {
              "@characteristic-name": "q_question",
              description: {
                $: "string",
                "@language-id": "string",
                "@language-locale-id": "string",
              },
              type: {
                $: "Answer",
                "@language-id": "string",
                "@list-agency-id": "string",
                "@list-agency-name": "string",
                "@list-id": "string",
                "@list-name": "string",
                "@list-scheme-uri": "string",
                "@list-uri": "string",
                "@list-version-id": "string",
                "@name": "string",
              },
              value: {
                $: "Dog Name here",
                "@language-id": "string",
                "@language-locale-id": "string",
              },
            },
          ],
        },
      },
    };

    data = JSON.stringify(data);

    var url =
      "https://api-mobile-ci-vfal.vodafone.com/enterprise-resources/public/identity/change-authentication-credentials/" +
      phone;
    //var url = 'http://localhost:3030/pin';
    axios
      .post(url, data, {
        headers: headers,
      })
      .then((response) => {
        dispatch({
          type: PIN_RECEIVED,
        });

        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          message: "Your password is changed succesfully!",
        });
      })
      .catch((error) => {
        dispatch({
          type: PIN_RECEIVED,
        });

        dispatch({
          type: RESET_PASSWORD_ERROR,
          message: error.response.data.message,
        });
      });
  };
};
