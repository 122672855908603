import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/app'
import * as serviceWorker from './serviceWorker';
import "babel-polyfill";


import './index.css'

const target = document.querySelector('#root')

//eslint-disable-next-line
try { window.top.document.domain } catch (e) { 
  var f = function() { document.body.innerHTML = ''; }
  setInterval(f, 1);  
  if (document.body) document.body.onload = f;
}

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
    	<App />
    </ConnectedRouter>
  </Provider>,
  target
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

