import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { connect } from 'react-redux';
import { translate } from '../../helpers';

import Home from '../home'
import UserContainer from '../user'
import { createMuiTheme } from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { PrivateRoute } from './PrivateRoute';
import PaymentModal from './../../components/PaymentModal'

import billImg from './../../bill.png'
import successImg from './../../correct.png'
import failImg from './../../error.png'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff'
    },
    secondary: {
      main: '#f44336',
    },
    textPrimary: '#fff',

    background: {
      default: '#333333'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'VodafoneRegular',
  }
});

const App = ({config}) => (
  <MuiThemeProvider theme={theme}>
    <main style={{ height: '100%' }}>
      <Switch>
        <Route exact path="/" component={UserContainer} />
        <PrivateRoute exact path="/home" component={Home} />
        {localStorage.getItem('allowPaymentAccess') && <PrivateRoute
          exact
          path='/payment'
          component={(props) =>
            <PaymentModal
              title="Pago Faturen e Dhjetor 2019"
              info="Fatura kushton 600lek, Lorem ipsum dolor sit amet"
              confirmButton={{
                title: translate('general_confirm_button', config)
              }}
              cancelButton={{
                title: translate('general_refuse_button', config),
                onClick:()=>props.history.push('/')
              }}
              logo={billImg}
              {...props}
              form
            />
          }
        />
        }
        <PrivateRoute
          exact
          path='/paymentSuccess'
          component={(props) =>
            <PaymentModal
              title={translate('general_success_message', config)}
              // info="Fatura kushton 600lek, Lorem ipsum dolor sit amet"
              // info={qs.parse(props.location.search).message}
              confirmButton={{
                title: translate('general_go_back_button', config),
                onClick:()=>props.history.push('/')
              }}
              logo={successImg}
              {...props}
            />
          }
        />

        <PrivateRoute
          exact
          path='/paymentFail'
          component={(props) =>
            <PaymentModal
              title={translate('general_failure_message', config)}
              // info="Lorem ipsum dolorsit amet dolorsit amet"
              // info={qs.parse(props.location.search).message}
              confirmButton={{
                title: translate('general_go_back_button', config),
                onClick:()=>props.history.push('/')
              }}
              logo={failImg}
              {...props}
            />
          }
        />

        <Redirect to="/home" />
      </Switch>
    </main>
  </MuiThemeProvider>
)


const mapStateToProps = state => {
	const { config } = state;

  return {
    config
	}
}

export default connect(mapStateToProps)(App)
