import React from 'react'
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom'
import qs from 'qs'

import Grid from './../VfGrid'
import CloseImg from './../../close.png'

import CancelButton from '../user/CancelButton';
import Button from '@material-ui/core/Button';

import withStyles from '@material-ui/core/styles/withStyles';

import Hidden from '@material-ui/core/Hidden';
import withWidth from '@material-ui/core/withWidth';
import Divider from '@material-ui/core/Divider';
import compose from 'recompose/compose';
import giftImg from './../../gift.png'
const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    backgroundColor: '#be1c02',
    borderColor: '#be1c02',
    color: '#fff',
    borderRadius: 0,
    fontSize: '20px',
    textTransform: 'capitalize',
    padding: '11px 37px',
    lineHeight: '27px',
    opacity: '1!important',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      fontSize: 16
    },
  },
  itemContainer: {
    // for iPhones to not overflow the buttons
    ['@media (max-width:360px)']: { // eslint-disable-line no-useless-computed-key
      maxWidth: '85%',
      flexBasis: '85%'
    }
  },
  paymentCloseImage: {
    // height: 25,
    cursor: 'pointer',
    width: 24,
    height: 24,
    objectFit: 'contain',
    [theme.breakpoints.up('sm')]: {
      width: 35,
      height: 35,
    },
    [theme.breakpoints.up('lg')]: {
      width: 60,
      height: 60,
    },
  },
  divider: {
    width: '70%',
    height: 1,
    backgroundColor: '#e0e0e0'
  },
  marginOneButton: {
    marginTop: '7vh',
    [theme.breakpoints.up('sm')]: {
      marginTop: '6vh',
    },
  },
  marginTwoButtons: {
    marginTop: '4vh',
    [theme.breakpoints.up('sm')]: {
      marginTop: '6vh',
    },
  },
  paymentTitleLabel: {
    fontSize: '24px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '40px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '56px',
    },
  },
  paymentInfoLabel: {
    fontSize: '16px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '26px',
    },
  },
  paymentImage: {
    width: '126px',
    height: '126px',
    objectFit: 'contain',
    margin: '10px 0px',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '140px',
      height: '140px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '160px',
      height: '160px',
      margin: '25px 0px',
    },
  },
  bonusText: {
    fontFamily: "VodafoneBold",
    margin: '-16px 0px'
  },
  giftImage: {
    objectFit: 'contain',
    width: '28px',
    height: '28px',
    marginRight: 8,
    [theme.breakpoints.up('sm')]: {
      width: '35px',
      height: '35px',
      marginRight: 10
    },
    [theme.breakpoints.up('lg')]: {
      width: '50px',
      height: '50px',
      marginRight: 15
    },
  }
});

function PaymentModal(props) {
  let { classes, title, info, confirmButton, cancelButton, logo, form, payment } = props;
  let gbBonusText = null
  // different on mobile
  const marginT = cancelButton ? classes.marginTwoButtons : classes.marginOneButton
  if (props.location.pathname === "/payment") {
    info = payment.info
    title = payment.title

    if (payment.inputsData.length === 0) {
      return <Redirect to='/home' />
    }
  } else {
    if (props.location.search !== "") {
      localStorage.setItem('paymentMessage', qs.parse(props.location.search).message)
      if (qs.parse(props.location.search).paid_bill_bonus)
        localStorage.setItem('gbBonusText', qs.parse(props.location.search).paid_bill_bonus)
      return <Redirect to={props.location.pathname} />
    }
    if (!localStorage.getItem('paymentMessage')) {
      return <Redirect to='/home' />
    }
    info = localStorage.getItem('paymentMessage')
    gbBonusText = localStorage.getItem('gbBonusText')
    localStorage.removeItem('paymentMessage')
    localStorage.removeItem('gbBonusText')
  }

  return (
    <div className="paymentModal">
      <Grid container justify="flex-end" marginT={'4vh'} paddingR={'5%'}>
        <img src={CloseImg} alt="" className={classes.paymentCloseImage} onClick={() => props.history.push('/')} />
      </Grid>
      <Grid container justify="center" className={marginT}>
        <Grid item xs={7} sm={12} className={classes.itemContainer}>

          <Grid container justify="center" className={classes.paymentTitleLabel}>
            <Grid item className="textCenter">
              {title}
            </Grid>
          </Grid>

          <Grid container justify="center" className={marginT} >
            <img src={logo} alt="" className={classes.paymentImage} />
          </Grid>

          {gbBonusText && <Grid container justify="center" className={marginT}>
            <Grid item xs={12} sm={6} md={4} className={`textCenter ${classes.paymentInfoLabel} ${classes.bonusText}`}>
              <Grid container justify="center" alignItems="center">
                <img alt="gift" src={giftImg} className={classes.giftImage} />{gbBonusText}
              </Grid>
            </Grid>
          </Grid>}

          <Grid container justify="center" className={marginT}>
            <Grid item xs={12} sm={6} md={4} className={`textCenter ${classes.paymentInfoLabel}`}>
              {info}
            </Grid>
          </Grid>

          <Hidden xsDown>
            <Grid container justify="center">
              <Divider
                flexItem
                variant='fullWidth'
                className={[classes.divider, marginT].join(' ')}
              />
            </Grid>
          </Hidden>

        </Grid>

        <div className="buttonContainer">
          {confirmButton &&
            (form ? <form method={payment.method} action={payment.url} onSubmit={() => localStorage.removeItem('allowPaymentAccess')}>
              {payment.inputsData.map((input, i) =>
                <input key={i} name={input.characteristicName} type="hidden" value={input.value} />
              )}

              <Button
                type="submit"
                // fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
              >
                {confirmButton.title}
              </Button>
            </form>
              :
              <Button
                type="submit"
                // fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={confirmButton.onClick ? confirmButton.onClick : undefined}
              >
                {confirmButton.title}
              </Button>
            )

          }

          {cancelButton && <CancelButton
            payment
            color="#afafaf"
            handleCancel={cancelButton.onClick}
            value={cancelButton.title}
          />}
        </div>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => {
  const { payment } = state.user;

  return {
    payment
  }
}

export default compose(
  withStyles(styles, {
    name: 'PaymentModal',
  }),
  withWidth(),
  connect(mapStateToProps),
)(PaymentModal);