import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Pin from "../pin";
import Error from "../error";
import InputError from "../InputError";
import ForgotPassword from "../forgotPassword";
import PasswordReset from "../PasswordReset";
import { translate, getRegex } from "../../../helpers";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
  main: {
    width: "auto",
    position: "absolute",
    bottom: "4rem",
    left: "4rem", // Fix IE 11 issue.
    //marginLeft: theme.spacing.unit * 3,
    //marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px 15px 24px",
    background: "transparent",
    boxShadow: "none",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    backgroundColor: "#be1c02",
    borderColor: "#be1c02",
    color: "#fff",
    borderRadius: "6px",
    fontSize: "20px",
    textTransform: "none",
    padding: "11px 37px",
    lineHeight: "27px",
    opacity: "1!important",
  },
  formInput: {
    background: "#fff",
    marginTop: "5px",
    marginBottom: "25px",
  },
  input: {
    padding: "13px 20px 12px",
    fontSize: "20px",
    height: "50px",
    fontFamily: "VodafoneRegular",
    boxSizing: "border-box",
    border: "1px solid #999",
  },
  label: {
    color: "#fff",
    fontSize: "18px",
    fontWeight: 500,
  },
  forgotPassword: {
    width: "100%",
    textAlign: "center",
    color: "#ccc",
    fontSize: "18px",
    fontWeight: "500",
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "20px",
  },
  submitDisabled: {
    backgroundColor: "#000000!important",
    borderColor: "#000000!important",
    color: "#cccc!important",
  },
  successMessage: {
    width: "100%",
    textAlign: "center",
    padding: "15px",
    border: "1px solid #029402",
    background: "#029402",
    color: "#fff",
    fontFamily: "VodafoneLight",
    fontSize: "17px",
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: {
        valid: true,
        message: "Numër i pavlefshëm",
        initial: true,
      },
      password: {
        valid: false,
        message: "Fjalëkalim i gabuar",
        initial: true,
      },
      button: {
        color: "primary",
        disabled: true,
        class: "submitDisabled",
      },
    };
  }

  handleInputBlur = (e) => {
    if (e.target.value.length > 0) {
      var config = this.props.config;

      var phone_exp = RegExp(getRegex("phoneNumber", config));
      var password_exp = RegExp(getRegex("passwordRule", config));

      if (e.target.name == "phone") {
        var valid = phone_exp.test(e.target.value);
        var message = translate("login_ht_username_err_hint", config);
      }

      if (e.target.name == "password") {
        var valid = password_exp.test(e.target.value);
        var message = translate("login_bt_pass_err_hint", config);
      }

      this.setState({
        [e.target.name]: {
          ...this.state[e.target.name],
          valid: valid,
          initial: false,
          message: message,
        },
      });
    } else {
      this.setState({
        [e.target.name]: {
          ...this.state[e.target.name],
          initial: true,
        },
      });
    }
  };

  handleInputKeyUp = (e) => {
    var phone = document.getElementById("phone").value;
    var password = document.getElementById("password").value;

    var config = this.props.config;

    var phone_exp = RegExp(getRegex("phoneNumber", config));
    var password_exp = RegExp(getRegex("passwordRule", config));

    var valid_phone = phone_exp.test(phone);

    var valid_password = password_exp.test(password);

    this.setState({
      [e.target.name]: {
        ...this.state[e.target.name],
        initial: false,
        valid: true,
      },
    });

    if (valid_phone && valid_password) {
      this.setState({
        button: {
          ...this.state.button,
          color: "secondary",
          disabled: false,
          class: "",
        },
      });
    } else {
      this.setState({
        button: {
          ...this.state.button,
          color: "primary",
          disabled: true,
          class: "submitDisabled",
        },
      });
    }
  };

  render() {
    const {
      classes,
      getPin,
      handleLoginPinSubmit,
      handleLogin,
      handleIdaaSLogin,
      login,
      idassLogin,
      forgotPassword,
      handleForgot,
      handleNumberSubmit,
      handleForgotPinSubmit,
      handlePasswordReset,
      handleCancel,
      config,
    } = this.props;

    if (forgotPassword.pin != "") {
      return (
        <PasswordReset
          forgotPassword={forgotPassword}
          handlePasswordReset={handlePasswordReset}
          handleCancel={handleCancel}
          config={config}
        />
      );
    }

    if (forgotPassword.forgot && getPin.pinSent) {
      return (
        <Pin
          config={config}
          handlePinSubmit={handleForgotPinSubmit}
          handleCancel={handleCancel}
        />
      );
    }

    if (forgotPassword.forgot) {
      return (
        <ForgotPassword
          handleNumberSubmit={handleNumberSubmit}
          handleCancel={handleCancel}
          config={config}
        />
      );
    }

    if (getPin.error) {
      return (
        <Pin
          error={getPin}
          config={config}
          handlePinSubmit={handleLoginPinSubmit}
          confirming={getPin.confirming}
          handleCancel={handleCancel}
        />
      );
    }

    if (getPin.pinSent) {
      return (
        <Pin
          config={config}
          handlePinSubmit={handleLoginPinSubmit}
          handleCancel={handleCancel}
          confirming={getPin.confirming}
        />
      );
    }

    const getErrors = () => {
      const { login } = this.props;

      if (login.error) {
        return <Error error={login} />;
      }
    };

    const resetPasswordMessage = () => {
      const { forgotPassword, config } = this.props;
      var message = translate("login_success", config);
      if (forgotPassword.message != "") {
        return (
          <InputLabel className={classes.successMessage}>{message}</InputLabel>
        );
      }
    };

    var username = translate("login_lb_username", config);
    var password = translate("login_lb_password", config);
    var login_btn = translate("login_bt_log_in", config);
    var username_hint = translate("login_ht_username_hint", config);
    var password_hint = translate("login_ht_password_hint", config);
    var forgot_password = translate("login_tx_forgot_pass", config);

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          {/* {getErrors()}
          {resetPasswordMessage()} */}
          {/* <form className={classes.form} onSubmit={handleLogin}>
            <InputError error={this.state.phone} />
            <InputLabel htmlFor="phone" className={classes.label}>
              {username}
            </InputLabel>
            <FormControl
              margin="normal"
              required
              fullWidth
              className={classes.formInput}
            >
              <Input
                onBlur={this.handleInputBlur}
                onKeyUp={this.handleInputKeyUp}
                id="phone"
                name="phone"
                autoComplete="phone"
                placeholder={username_hint}
                classes={{ input: classes.input }}
              />
            </FormControl>
            <InputError error={this.state.password} />
            <InputLabel htmlFor="password" className={classes.label}>
              {password}
            </InputLabel>
            <FormControl
              margin="normal"
              required
              fullWidth
              className={classes.formInput}
            >
              <Input
                onBlur={this.handleInputBlur}
                onKeyUp={this.handleInputKeyUp}
                name="password"
                type="password"
                id="password"
                placeholder={password_hint}
                autoComplete="current-password"
                classes={{ input: classes.input }}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color={this.state.button.color}
              className={[
                classes.submit,
                classes[this.state.button.class],
              ].join(" ")}
              disabled={this.state.button.disabled}
            >
              {login.checking ? <CircularProgress size={27} /> : login_btn}
            </Button>
          </form> */}
          {getErrors()}
          <div
            style={{
              width: "100%",
            }}
          >
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={handleIdaaSLogin}
              disabled={idassLogin.checking}
            >
              {idassLogin.checking ? (
                <CircularProgress size={27} />
              ) : (
                "Hyr në Ebill"
              )}
            </Button>
          </div>
          {/* 
          <InputLabel onClick={handleForgot} className={classes.forgotPassword}>
            {forgot_password}
          </InputLabel> */}
        </Paper>
      </main>
    );
  }
}

export default withStyles(styles)(Login);
