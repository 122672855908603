import axios from "axios";
import { push } from "connected-react-router";

export const ACCOUNTS_REQUESTED = "ACCOUNTS_REQUESTED";
export const ACCOUNTS_RECEIVED = "ACCOUNTS_RECEIVED";
export const ACCOUNTS_ERROR = "ACCOUNTS_ERROR";

export const AUTH_ERROR = "AUTH_ERROR";
export const SET_TOKEN = "SET_TOKEN";

export const getClientAccounts = () => {
  return (dispatch) => {
    dispatch({
      type: ACCOUNTS_REQUESTED,
    });

    var token = localStorage.getItem("token");
    var hashedMsisdn = localStorage.getItem("hashedMsisdn");
    var isIdLogin = !!localStorage.getItem("IDLogin");

    // var url = 'https://api-mobile-vfal.vodafone.com/auth/customerBillAPI/v2/customerBill?msisdn='+hashedMsisdn;
    // var url = 'http://52.29.171.250:8001/public/customerBillAPI/v2/customerBill?msisdn='+hashedMsisdn
    // var url = 'http://52.29.171.250:8001/public/customerBillAPI/v2/customerBill?msisdn=hashedmsisdn_355698933127'
    var url =
      "https://api-mobile-ci-vfal.vodafone.com/auth/customerBillAPI/v2/customerBill?msisdn=" +
      hashedMsisdn;

    var headers = {
      "X-Source-System": "ebill",
      "X-Source-Operator": "ebill",
      "X-Source-CountryCode": "al",
      "Accept-Language": "al",
      Authorization: "Bearer " + token,
    };

    axios
      .get(url, {
        headers: headers,
        data: {},
      })
      .then((response) => {
        dispatch({
          type: ACCOUNTS_RECEIVED,
          accounts: response.data,
        });
      })
      .catch((error) => {
        if (typeof error.response == "undefined" || error.response.data == "") {
          var message =
            "Diçka nuk funksionoi. Ju lutem provoni perseri me vone.";
        } else if (error.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("hashedMsisdn");
          localStorage.removeItem("phone");
          localStorage.removeItem("IDLogin");
          dispatch(push("/"));
          return;
        } else {
          var message = error.response.data.message;
        }

        dispatch({
          type: ACCOUNTS_ERROR,
          error: message,
        });
      });
  };
};

export const DOCUMENTS_REQUESTED = "DOCUMENTS_REQUESTED";
export const DOCUMENTS_RECEIVED = "DOCUMENTS_RECEIVED";
export const DOCUMENTS_ERROR = "DOCUMENTS_ERROR";

export const getAccountDocuments = (account) => {
  return (dispatch) => {
    dispatch({
      type: DOCUMENTS_REQUESTED,
    });

    var token = localStorage.getItem("token");
    var hashedMsisdn = localStorage.getItem("hashedMsisdn");

    // var url = 'https://api-mobile-vfal.vodafone.com/auth/customerBillAPI/v2/customerBill?msisdn='+hashedMsisdn+'&id='+account;
    // var url = 'http://52.29.171.250:8001/public/customerBillAPI/v2/customerBill?msisdn='+hashedMsisdn+'&id='+account;
    // var url = 'http://52.29.171.250:8001/public/customerBillAPI/v2/customerBill?msisdn=hashedmsisdn_355698933127&id=D4A890EBDABBE01E0102DA6F54A60258'
    var url =
      "https://api-mobile-ci-vfal.vodafone.com/auth/customerBillAPI/v2/customerBill?msisdn=" +
      hashedMsisdn +
      "&id=" +
      account;

    var headers = {
      "X-Source-System": "ebill",
      "X-Source-Operator": "ebill",
      "X-Source-CountryCode": "al",
      "Accept-Language": "al",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    axios
      .get(url, {
        headers: headers,
        data: {},
      })
      .then((response) => {
        dispatch({
          type: DOCUMENTS_RECEIVED,
          data: {
            documents: response.data,
            account: account,
          },
        });
      })
      .catch((error) => {
        if (typeof error.response == "undefined" || error.response.data == "") {
          var message =
            "Diçka nuk funksionoi. Ju lutem provoni perseri me vone.";
        } else if (error.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("hashedMsisdn");
          localStorage.removeItem("phone");
          localStorage.removeItem("IDLogin");
          dispatch(push("/"));
          return;
        } else {
          var message = error.response.data.message;
        }

        dispatch({
          type: DOCUMENTS_ERROR,
          message: message,
        });
      });
  };
};

export const DOCUMENT_DOWNLOADING = "DOCUMENT_DOWNLOADING";
export const DOCUMENT_DOWNLOADED = "DOCUMENT_DOWNLOADED";

export const downloadFile = (accountId, documentId, name) => {
  return (dispatch) => {
    var url =
      "https://api-web-ci-vfal.vodafone.com/auth/customerBillAPI/v2/customerBill/" +
      accountId +
      "/document/" +
      documentId;

    var token = localStorage.getItem("token");

    var headers = {
      "X-Source-System": "ebill",
      "X-Source-Operator": "ebill",
      "X-Source-CountryCode": "al",
      "Accept-Language": "al",
      Authorization: "Bearer " + token,
    };

    dispatch({
      type: DOCUMENT_DOWNLOADING,
      document: documentId,
    });

    // axios.get(url, {
    // 	headers: headers
    // })

    axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: headers,
    })
      .then((response) => {
        if (navigator.appVersion.toString().indexOf(".NET") > 0) {
          window.navigator.msSaveBlob(new Blob([response.data]), name);
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }

        dispatch({
          type: DOCUMENT_DOWNLOADED,
        });
      })
      .catch((error) => {
        dispatch({
          type: DOCUMENT_DOWNLOADED,
        });

        if (typeof error.response == "undefined" || error.response.data == "") {
          var message =
            "Diçka nuk funksionoi. Ju lutem provoni perseri me vone.";
        } else if (error.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("hashedMsisdn");
          localStorage.removeItem("IDLogin");
          dispatch(push("/"));
          return;
        } else {
          var message = error.response.data.message;
        }

        // @todo
        // show messsage to user
      });
  };
};

export const CONFIG_REQUESTED = "CONFIG_REQUESTED";
export const CONFIG_RECEIVED = "CONFIG_RECEIVED";
export const CONFIG_ERROR = "CONFIG_ERROR";

export const getConfig = () => {
  return (dispatch) => {
    dispatch({
      type: CONFIG_REQUESTED,
    });

    //var url = 'http://localhost:3030/menu';
    var url =
      "https://api-mobile-ci-vfal.vodafone.com/enterprise-resources/public/technical/configurations?version=1.0.0&msisdn=35569*******&hash=12312321751";

    var headers = {
      "Content-Type": "application/json",
      "X-Source-System": "ebill",
      "Accept-Language": "al",
    };

    axios
      .get(url, {
        headers: headers,
      })
      .then((response) => {
        let config = response.data;

        var pages = config.pages;

        var my_pages = {};

        for (var i in pages) {
          var page = pages[i];
          my_pages[page.page] = page;

          for (var j in page.items) {
            var item = page.items[j];
            my_pages[page.page][item.key] = item;
          }
        }

        /* faults */
        var my_faults = {};
        var faults = config.faults;
        for (var i in faults) {
          var fault = faults[i];
          my_faults[fault.key] = fault;
        }

        /* menu */
        var my_menus = {
          personal: [],
          business: [],
        };
        var menus = config.menu;

        for (var i in menus) {
          var menu = menus[i];

          if (menu.type == "personal") {
            my_menus.personal.push(menu);
          } else {
            my_menus.business.push(menu);
          }
        }

        config.pages = my_pages;
        config.faults = my_faults;
        config.menu = my_menus;

        dispatch({
          type: CONFIG_RECEIVED,
          config: config,
        });

        var config_sting = JSON.stringify(config);
        localStorage.setItem("config", config_sting);
      })
      .catch((error) => {
        if (typeof error.response == "undefined" || error.response.data == "") {
          var message =
            "Diçka nuk funksionoi. Ju lutem provoni perseri me vone.";
        } else {
          var message = error.response.data.message;
        }

        dispatch({
          type: CONFIG_ERROR,
          error: message,
        });
      });
  };
};
