import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Error from '../error';
import InputError from '../InputError';
import Pin from '../pin';
import { translate, getRegex } from '../../../helpers';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    //marginLeft: theme.spacing.unit * 3,
    //marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
},
paper: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 15px 24px',
    background: 'transparent',
    boxShadow: 'none',
},
form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
},
submit: {
    marginTop: theme.spacing.unit * 3,
    backgroundColor: '#be1c02',
    borderColor: '#be1c02',
    color: '#fff',
    borderRadius: 0,
    fontSize: '20px',
    textTransform: 'capitalize',
    padding: '11px 37px',
    lineHeight: '27px',
    opacity: '1!important',
},
formInput:{
    background: '#fff',
    marginTop: '5px',
    marginBottom: '25px',
},
input: {
    padding: '13px 20px 12px',
    fontSize: '20px',
    height: '50px',
    fontFamily: "VodafoneRegular",
    boxSizing: 'border-box',
    border: '1px solid #999',
},
label: {
    color: '#fff',
    fontSize: '18px',
    fontWeight: 500,
},
errorIcon: {
    background: '#fecb00',
    width: '30px',
    margin: 0,
    display: 'inline-block',
    border: '1px solid #fecb00',
    textAlign: 'center',
    padding: '5px 0px 10px 0px',
  },
  error: {
    width: '100%',
    marginBottom: '20px',
  },
  errorMessage: {
    background: '#494d4e',
    color: '#fff',
    width: 'calc(100% - 54px)',
    margin: 0,
    border: '1px solid #fecb00',
    display: 'inline-block',
    padding: '10px',
  },
  submitDisabled: {
    backgroundColor: '#666!important',
    borderColor: '#666!important',
    color: '#333!important',
  },
  successMessage: {
    width: '100%',
    textAlign: 'center',
    padding: '15px',
    border: '1px solid #029402',
    background: '#029402',
    color: '#fff',
    fontFamily: 'VodafoneLight',
    fontSize: '17px',
  }
});

class Register extends Component{
    constructor(props){
      super(props);
      this.state = {
        phone: {
          valid: false,
          message: 'Numër i pavlefshëm',
          initial: true,
        },
        password: {
          valid: false,
          message: 'Fjalëkalim i gabuar',
          initial: true,
        },
        confirm_password: {
            valid: false,
            message: 'Fjalëkalim i gabuar',
            initial: true,
        },
        button: {
          color: 'primary',
          disabled: true,
          class: 'submitDisabled',
        }
      }
    }

    handleInputBlur = (e) =>{
      if(e.target.value.length > 0){
        var config = this.props.config;

        var phone_exp = RegExp(getRegex('phoneNumber', config));
        var password_exp = RegExp(getRegex('passwordRule', config));

        if(e.target.name == 'phone'){
          var valid = phone_exp.test(e.target.value);
          var message = translate('login_ht_username_err_hint', config);
        }

        if(e.target.name == 'password'){
          var valid = password_exp.test(e.target.value);
          var message = translate('login_bt_pass_err_hint', config);
        }

        if(e.target.name == 'confirm_password'){
          var valid = password_exp.test(e.target.value);
          var message = translate('login_pass_match', config);
        }
        
        this.setState({
          [e.target.name]: {
            ...this.state[e.target.name],
            valid: valid,
            message: message
          }
        })

      }

      
    }


    handleInputKeyUp = (e) => {
        
        var element = e.target;

        var phone = document.getElementById('phone').value
        var password = document.getElementById('password').value
        var confirm_password = document.getElementById('confirm-password').value
        
        var config = this.props.config;

        var phone_exp = RegExp(getRegex('phoneNumber', config));
        var password_exp = RegExp(getRegex('passwordRule', config));

        var valid_phone = phone_exp.test(phone);
        

        var valid_password = password_exp.test(password);
        
        var valid_repassword = password_exp.test(confirm_password);
        
        if(password != confirm_password){
          valid_repassword = false;
        }

        this.setState({
            [e.target.name]: {
              ...this.state[e.target.name],
              initial: false,
              valid: true
            }
        });

        if(valid_phone && valid_password && valid_repassword){
            this.setState({
                button: {
                  ...this.state.button,
                  color: 'secondary',
                  disabled: false,
                  class: '',
                }
            });
        }else{
            this.setState({
                button: {
                  ...this.state.button,
                  color: 'primary',
                  disabled: true,
                  class: 'submitDisabled',
                }
            });
        }
    }

    render(){

        const { classes, getPin, handleRegisterPinSubmit, register, handleCancel, config } = this.props;

        if(getPin.pinSent){
            return (
                <Pin config={config} handlePinSubmit={handleRegisterPinSubmit} handleCancel={handleCancel}/>
            )
        }

        const getErrors = () => {

            if(register.error){
                return (
                    <Error error={register} />
                )
            }

            if(getPin.error){
                return (
                    <Error error={getPin} />
                )
            }
        }

        const registerMessage = () => {
          const { register, config } = this.props;
          var message = translate('login_register_success', config);
          if(register.registered){
            return (
              <InputLabel className={classes.successMessage}>{ message }</InputLabel>
            )
          }
        }
        

        var username = translate('login_lb_username', config);
        var password = translate('login_lb_password', config);
        var register_btn = translate('login_bt_register', config);
        var username_hint = translate('login_ht_username_hint', config);
        var password_hint = translate('login_ht_password_hint', config);
        var retype_password = translate('login_tx_confirm_pass', config);
        
        return (
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    {getErrors()}
                    {registerMessage()}
                    <form className={classes.form} onSubmit={this.props.handleRegister}>
                        <InputError error={this.state.phone}/>
                        <InputLabel htmlFor="phone" className={classes.label}>{username}</InputLabel>
                        <FormControl margin="normal" required fullWidth className={classes.formInput}>
                            <Input onBlur={this.handleInputBlur} onKeyUp={this.handleInputKeyUp} id="phone" name="phone" autoComplete="phone" autoFocus placeholder={username_hint} classes={{input: classes.input}}/>
                        </FormControl>
                        
                        <InputError error={this.state.password}/>
                        <InputLabel htmlFor="password" className={classes.label}>{password}</InputLabel>
                        <FormControl margin="normal" required fullWidth className={classes.formInput}>
                            <Input onBlur={this.handleInputBlur} onKeyUp={this.handleInputKeyUp} name="password" placeholder={password_hint} type="password" id="password" autoComplete="current-password" classes={{input: classes.input}}/>
                        </FormControl>
                        
                        <InputError error={this.state.confirm_password}/>
                        <InputLabel htmlFor="confirm-password" className={classes.label}>{retype_password}</InputLabel>
                        <FormControl margin="normal" required fullWidth className={classes.formInput}>
                            <Input onBlur={this.handleInputBlur} onKeyUp={this.handleInputKeyUp} name="confirm_password" placeholder={password_hint} type="password" id="confirm-password" autoComplete="current-password" classes={{input: classes.input}}/>
                        </FormControl>

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="secondary"
                          className={[classes.submit, classes[this.state.button.class]].join(' ')}
                          disabled={this.state.button.disabled}
                        >
                            {register_btn}
                        </Button>
                    </form>
                </Paper>
            </main>
        );
    }
}


export default withStyles(styles)(Register);