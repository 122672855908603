import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Banner from './Banner';
import Breadcrumb from './Breadcrumb';
import HeaderTabs from './HeaderTabs';
import Menu from './Menu';
import HomeFooter from './Footer';
import PaymentModal from './../../components/PaymentModal'

const Home = props => (
  <>
    {/* <PaymentModal
      title="Pago Faturen e Dhjetor 2019"
      info="Fatura kushton 600lek, Lorem ipsum dolor sit amet"
      confirmButton={{
        title: 'Confirm'
      }}
      cancelButton={{
        title: 'No, thank you'
      }}
    /> */}
    {/* <PaymentModal
      title="Congratulations"
      info="Fatura kushton 600lek, Lorem ipsum dolor sit amet"
      confirmButton={{
        title: 'Go Back'
      }}
    /> */}
    {/* <PaymentModal
      title="Ooooops"
      info="Lorem ipsum dolorsit amet dolorsit amet"
      confirmButton={{
        title: 'Go Back'
      }}
    />
    */}

    <div style={{ background: '#f4f4f4' }}>
      <Menu />
      <Banner />
      <Breadcrumb />
      <HeaderTabs />
      <HomeFooter />
    </div>
    </> 

)

const mapStateToProps = state => {

  return state
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: () => push('/home')
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)