import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

const styles = (theme) => ({
  banner: {
    textAlign: "center",
    background: "url(/banner.jpg)",
    padding: "50px",
    color: "#fff",
    height: "360px",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      height: "250px",
    },
  },
  text: {
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
    },
  },
});

class Home extends Component {
  render() {
    const { classes, config } = this.props;

    try {
      var banner = config.pages.dashboard.fatElekVod.value;
    } catch {
      var banner = "";
    }

    return (
      <div className={classes.banner}>
        <Typography variant="h2" className={classes.text}>
          {banner}
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { config } = state;

  return { config };
};

export default connect(mapStateToProps)(withStyles(styles)(Home));
