import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Error from '../error';
import CancelButton from '../CancelButton';
import { translate, getRegex } from '../../../helpers';
import InputError from '../InputError';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    //marginLeft: theme.spacing.unit * 3,
    //marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
},
paper: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 15px 24px',
    background: 'transparent',
    boxShadow: 'none',
},
form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
},
submit: {
    marginTop: theme.spacing.unit * 3,
    backgroundColor: '#be1c02',
    borderColor: '#be1c02',
    color: '#fff',
    borderRadius: 0,
    fontSize: '20px',
    textTransform: 'capitalize',
    padding: '11px 37px',
    lineHeight: '27px',
    opacity: '1!important',
},
formInput:{
    background: '#fff',
    marginTop: '5px',
    marginBottom: '25px',
},
input: {
    padding: '13px 20px 12px',
    fontSize: '20px',
    height: '50px',
    fontFamily: "VodafoneRegular",
    boxSizing: 'border-box',
    border: '1px solid #999',
},
label: {
    color: '#fff',
    fontSize: '18px',
    fontWeight: 500,
},
errorIcon: {
    background: '#fecb00',
    width: '30px',
    margin: 0,
    display: 'inline-block',
    border: '1px solid #fecb00',
    textAlign: 'center',
    padding: '5px 0px 10px 0px',
  },
  error: {
    width: '100%',
    marginBottom: '20px',
  },
  errorMessage: {
    background: '#494d4e',
    color: '#fff',
    width: 'calc(100% - 54px)',
    margin: 0,
    border: '1px solid #fecb00',
    display: 'inline-block',
    padding: '10px',
  }
});

class PasswordReset extends Component{
    constructor(props){
      super(props);
      this.state = {
        password: {
          valid: false,
          message: 'Fjalëkalim i gabuar',
          initial: true,
        },
        confirm_password: {
            valid: false,
            message: 'Fjalëkalim i gabuar',
            initial: true,
        },
        button: {
          color: 'primary',
          disabled: true,
          class: 'submitDisabled',
        }
      }
    }

    handleInputBlur = (e) =>{
      if(e.target.value.length > 0){
        var config = this.props.config;

        var password_exp = RegExp(getRegex('passwordRule', config));

        if(e.target.name == 'password'){
            var valid = password_exp.test(e.target.value);
            var message = translate('login_bt_pass_err_hint', config);

        }

        if(e.target.name == 'confirm_password'){
            var valid = password_exp.test(e.target.value);
            var message = translate('login_pass_match', config);
            var element = e.target;

            var password = document.getElementById('password').value
            var confirm_password = document.getElementById('confirm-password').value
            
            if(password != confirm_password){
                valid = false;
            }
        }



        this.setState({
          [e.target.name]: {
            ...this.state[e.target.name],
            valid: valid,
            message: message
          }
        })

      }

      
    }


    handleInputKeyUp = (e) => {
        
        var element = e.target;

        var password = document.getElementById('password').value
        var confirm_password = document.getElementById('confirm-password').value
        
        var config = this.props.config;

        var password_exp = RegExp(getRegex('passwordRule', config));        

        var valid_password = password_exp.test(password);
        var valid_repassword = password_exp.test(confirm_password);
        
        if(password != confirm_password){
          valid_repassword = false;
        }

        this.setState({
            [e.target.name]: {
              ...this.state[e.target.name],
              initial: false,
              valid: true
            }
        });

        if(valid_password && valid_repassword){
            this.setState({
                button: {
                  ...this.state.button,
                  color: 'secondary',
                  disabled: false,
                  class: '',
                }
            });
        }else{
            this.setState({
                button: {
                  ...this.state.button,
                  color: 'primary',
                  disabled: true,
                  class: 'submitDisabled',
                }
            });
        }
    }

    render(){

        const { classes, forgotPassword, handlePasswordReset, handleCancel, config } = this.props;

        const getErrors = () => {

            if(forgotPassword.error){
                return (
                    <Error error={forgotPassword} />
                )
            }
        }

        if(forgotPassword.status == 'success'){
          return (
            <div style={{color: '#fff', fontSize: '18px'}}>{forgotPassword.message}</div>
          )
        }
        
        var password = translate('login_lb_password', config);
        var password_hint = translate('login_ht_password_hint', config);
        var retype_password = translate('login_tx_confirm_pass', config);
        
        
        return (
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    {getErrors()}
                    <form className={classes.form} onSubmit={handlePasswordReset}>
                        <InputError error={this.state.password}/>
                        <InputLabel htmlFor="password" className={classes.label}>{password}</InputLabel>
                        <FormControl margin="normal" required fullWidth className={classes.formInput}>
                            <Input onBlur={this.handleInputBlur} onKeyUp={this.handleInputKeyUp} placeholder={password_hint} name="password" type="password" id="password" autoComplete="current-password" classes={{input: classes.input}}/>
                        </FormControl>
                        
                        <InputError error={this.state.confirm_password}/>
                        <InputLabel htmlFor="confirm-password" className={classes.label}>{retype_password}</InputLabel>
                        <FormControl margin="normal" required fullWidth className={classes.formInput}>
                            <Input onBlur={this.handleInputBlur} onKeyUp={this.handleInputKeyUp} placeholder={password_hint} name="confirm_password" type="password" id="confirm-password" autoComplete="current-password" classes={{input: classes.input}}/>
                        </FormControl>

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="secondary"
                          className={classes.submit}
                          disabled={this.state.button.disabled}
                        >
                            Reset Password
                        </Button>
                        <CancelButton config={config} handleCancel={handleCancel}/>
                    </form>
                </Paper>
            </main>
        );
    }
}


export default withStyles(styles)(PasswordReset);