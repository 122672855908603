import React, { Component } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import { ErrorOutlined } from "@material-ui/icons"
import errorIcon from './../../warning-icon.png'
const styles = theme => ({
	errorIcon: {
		height: "100%",
		position: "absolute",
		background: "#FECB01",
		width: "50px",
		margin: 0,
		// display: "inline-block",
    border: "1.5px solid #FECB01",
    borderRadius: "10px 0px 0px 10px",
		textAlign: "center",
    display: "flex",
    alignItems: "center"
	},
	errorMessage: {
		background: "#fff",
		color: "#333",
    // width: "calc(100% - 50px)",
    paddingRight: '50px',
		margin: 0,
    border: "1.5px solid #FECB01",
    borderRadius: "0px 10px 10px 0px",
		display: "inline-block",
		padding: "10px",
		marginLeft: "50px",
    fontSize: "18px",
    [theme.breakpoints.down('sm')]: {
      paddingRight: '10px',
    },
	},
	errorContainer: {
		position: "relative",
		// marginBottom: "15px",
		// width: "80%",
		// marginLeft: "10%",
		// marginTop: "10px"
  },
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  ops:{
    fontFamily: 'VodafoneBold'
  }
})

class Error extends Component {
  

	render() {
		const { error, classes } = this.props

		return (
      <div className={classes.root}>
        <div className={classes.errorContainer}>
          <span className={classes.errorIcon}>
            {/* <ErrorOutlined color="action" style={{ fontSize: 20, color: "#494d4e" }} /> */}
            <img src={errorIcon} width={45} alt=""/>
          </span> 
          <span className={classes.errorMessage}>
            <div className={classes.ops}>Ooooops</div>
            <span>{error}</span>
          </span>
        </div>
      </div>
		)
	}


}


export default withStyles(styles)(Error)