import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	menu: {
		margin: '0px 0px 0px 0',
		background: '#4a4d4e',
	    color: '#fff',
	    zIndex: '3',
	    position: 'relative',
	},
	submenuItem: {
		display: 'inline-block',
		'&:hover': {
			background: '#fff',
		}
	},
	submenu: {
		minWidth: '100px',
	},
	list: {
		margin: 0,
		height: '30px',
    	lineHeight: '30px',
    	paddingLeft: '75px',
	},
	firstItem: {
	    marginLeft: 'calc((100vw - 1200px) / 2)',
	},
	submenuLink: {
		color: '#fff',
    	padding: '5px 15px',
    	fontSize: '15px',
    	'&:hover': {
    		color: '#4a4d4e',
    		textDecoration: 'none',
    	}
	},
	active: {
		color: '#4a4d4e',
		background: '#fff',
	}
})

class SmallMenu extends Component {
	render(){
		const { classes, handleMenuChange, menu } = this.props;

		const getActiveClass = (activeMenu) => {
			if(activeMenu == menu){
				return classes.active;
			}

			return ''
		}

		return (
			<div className={classes.menu}>
				<ul className={classes.list}>
					<li className={[classes.submenuItem, classes.firstItem, getActiveClass('personal')].join(' ')}>
						<a onClick={() => handleMenuChange('personal')} className={[classes.submenuLink, classes.firstLink, getActiveClass('personal')].join(' ')} href="#">Personale</a>
					</li>
					<li className={[classes.submenuItem, getActiveClass('business')].join(' ')}>
						<a onClick={() => handleMenuChange('business')} className={[classes.submenuLink, getActiveClass('business')].join(' ')} href="#">Biznes</a>
					</li>
				</ul>
	    		
      		</div>
    );
  }
}

export default withStyles(styles)(SmallMenu);