import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import CancelButton from '../CancelButton';
import { translate } from '../../../helpers';
import Error from '../error';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    //marginLeft: theme.spacing.unit * 3,
    //marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
},
paper: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 15px 24px',
    background: 'transparent',
    boxShadow: 'none',
},
form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
},
submit: {
    marginTop: theme.spacing.unit * 3,
    backgroundColor: '#be1c02',
    borderColor: '#be1c02',
    color: '#fff',
    borderRadius: 0,
    fontSize: '20px',
    textTransform: 'capitalize',
    padding: '11px 37px',
    lineHeight: '27px',
    opacity: '1!important',
},
formInput:{
    background: '#fff',
    marginTop: '5px',
    marginBottom: '25px',
},
input: {
    padding: '13px 20px 12px',
    fontSize: '20px',
    height: '50px',
    fontFamily: "VodafoneRegular",
    boxSizing: 'border-box',
    border: '1px solid #999',
},
label: {
    color: '#fff',
    fontSize: '18px',
    fontWeight: 500,
}
});

class Pin extends Component{

    render(){

        const { classes, handleCancel, config, error, confirming } = this.props;

        var confirm = translate('login_tx_confirm', config);
        var pin = translate('login_tx_enter_pin', config);
        var pin_hint = translate('login_tx_enter_pin_hint', config);
        
        const getError = () => {
            if(typeof error != 'undefined'){
                return (
                    <Error error={error} />
                )
            }
        }

        return (
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    <form className={classes.form} onSubmit={this.props.handlePinSubmit}>
                        {getError()}
                        <InputLabel htmlFor="pin" className={classes.label}>{pin}</InputLabel>
                        <FormControl margin="normal" required fullWidth className={classes.formInput}>
                            <Input id="pin" name="pin" autoComplete="pin" autoFocus placeholder={pin_hint} classes={{input: classes.input}}/>
                        </FormControl>

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="secondary"
                          className={classes.submit}
                        >   
                            {confirming ? 
                              <CircularProgress size={27} className={classes.progress} />
                              :
                              confirm
                            }
                        </Button>
                        <CancelButton config={config} handleCancel={handleCancel}/>
                    </form>
                </Paper>
            </main>
        );
    }
}


export default withStyles(styles)(Pin);