import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { translate } from "../../helpers";

const styles = (theme) => ({
  breadcrumb: {
    borderBottom: "1px solid #ccc",
    borderTop: "1px solid #ccc",
    padding: "18px 0",
    textAlign: "left",
    background: "#333",
    color: "#ccc",
    borderColor: "#666",
  },
  content: {
    maxWidth: "1200px",
    margin: "0 auto",
    paddingLeft: "15px",
  },
  text: {
    color: "#fff",
  },
});

class Home extends Component {
  render() {
    const { classes, config } = this.props;

    var start = translate("fillimi", config);
    var bill = translate("fatElekVod", config);

    return (
      <div className={classes.breadcrumb}>
        <div className={classes.content}>
          <span>{start}</span> > <span>{bill}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { config } = state;

  return { config };
};

export default connect(mapStateToProps)(withStyles(styles)(Home));
