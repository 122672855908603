import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { ErrorOutlined } from '@material-ui/icons';

const styles = theme => ({
  errorIcon: {
    height: '100%',
    position: 'absolute',
    background: '#fecb00',
    width: '50px',
    margin: 0,
    display: 'inline-block',
    border: '1px solid #fecb00',
    textAlign: 'center',
    padding: '5px 0px 10px 0px',
    '&::after': {
      border: '8px solid transparent',
      borderTopColor: '#fecb00',
      display: 'block',
      height: 0,
      width: 0,
      WebkitTransform: 'rotate(0)',
      MozTransform: 'rotate(0)',
      MsTransform: 'rotate(0)',
      OTransform: 'rotate(0)',
      transform: 'rotate(0)',
      left: '50%',
      marginLeft: '-8px',
      content: "''",
      position: 'absolute',
      top: '100%',
    }
  },
  errorMessage: {
    background: '#494d4e',
    color: '#fff',
    width: 'calc(100% - 50px)',
    margin: 0,
    border: '1px solid #fecb00',
    display: 'inline-block',
    padding: '10px',
    marginLeft: '50px',
  },

  errorContainer: {
    position: 'relative',
    marginBottom: '-15px',
  }
});

class InputError extends Component {
  render() {
    const { classes, error } = this.props;
    if(error.valid || error.initial){
      return null;
    }
    return (
      <div className={classes.errorContainer}>
        <div className={classes.errorIcon}>
            <ErrorOutlined color="error" style={{ fontSize: 20, color: '#494d4e' }} />
        </div>
        <div className={classes.errorMessage}>
          <span>{error.message}</span>
        </div>
      </div>
    );
  }


}


export default withStyles(styles)(InputError);