import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Login from "./login";
import Register from "./register";
import { translate } from "../../helpers";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
      }}
    >
      {props.children}
    </Typography>
  );
}

const styles = (theme) => ({
  paper: {
    backgroundColor: "#404040",
    boxShadow: "none",
    borderBottom: "2px solid #484948",
  },
  tabs: {
    backgroundColor: "#404040",
  },
  tab: {
    color: "#afafaf",
    fontSize: "20px",
    textTransform: "capitalize",
    width: "auto",
    minWidth: "auto",
    paddingLeft: "5px",
    paddingRight: "5px",
    "&:hover": {
      color: "#fff",
    },
    "&:after": {
      border: "6px solid transparent",
      borderTopColor: "#fff",
      display: "block",
      height: 0,
      width: 0,
      webkitTransform: "rotate(0)",
      mozTransform: "rotate(0)",
      msTransform: "rotate(0)",
      oTransform: "rotate(0)",
      transform: "rotate(0)",
      color: "#ffffff",
      content: "a",
      position: "absolute",
      top: "100%",
      left: "50%",
      marginLeft: "-8px",
      zIndex: 1,
    },
  },
});

class Authentication extends Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const {
      classes,
      getPin,
      handleRegisterPinSubmit,
      handleLoginPinSubmit,
      register,
      login,
      idassLogin,
      handleLogin,
      forgotPassword,
      handleForgot,
      handleNumberSubmit,
      handleForgotPinSubmit,
      handlePasswordReset,
      handleCancel,
      config,
      handleIdaaSLogin,
    } = this.props;
    const { value } = this.state;

    var login_txt = translate("login_bt_log_in", config);
    var register_txt = translate("login_tx_register", config);
    return (
      <div className={classes.root}>
        {/* <Paper className={classes.paper}> */}
        {/* <Tabs
            value={value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            className={classes.tabs}
          >
            <Tab
              onClick={handleCancel}
              className={classes.tab}
              label={login_txt}
            />
            <Tab
              onClick={handleCancel}
              className={classes.tab}
              label={register_txt}
            />
          </Tabs>
        </Paper> */}
        {value === 0 && (
          <TabContainer>
            <Login
              handleLoginPinSubmit={handleLoginPinSubmit}
              getPin={getPin}
              login={login}
              handleLogin={handleLogin}
              handleIdaaSLogin={handleIdaaSLogin}
              idassLogin={idassLogin}
              forgotPassword={forgotPassword}
              handleForgot={handleForgot}
              handleNumberSubmit={handleNumberSubmit}
              handleForgotPinSubmit={handleForgotPinSubmit}
              handlePasswordReset={handlePasswordReset}
              handleCancel={handleCancel}
              config={config}
            />
          </TabContainer>
        )}
        {/* {value === 1 && (
          <TabContainer>
            <Register
              register={register}
              getPin={getPin}
              handleRegister={this.props.handleRegister}
              handleRegisterPinSubmit={handleRegisterPinSubmit}
              handleCancel={handleCancel}
              config={config}
            />
          </TabContainer>
        )} */}
      </div>
    );
  }
}

export default withStyles(styles)(Authentication);
