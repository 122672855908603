import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	svgIcon: {
		fill: 'none!important',
		stroke: 'currentcolor!important',
		strokeWidth: '7px',
		verticalAlign: 'middle!important',
		width: '24px',
		height: '24px',
		color: '#e60000',
		transition: 'transform .4s',
	},
	secondaryArrow: {
		transform: 'rotateZ(-90deg)',
	}
})


class ArrowIcon extends Component {
	render(){
		const { classes, type, color } = this.props;
		return (
			<svg style={{color: color}} className={[classes.svgIcon, classes[type]].join(' ')} viewBox="0 0 144 76" id="icon-chevron-down" width="100%" height="100%"><polyline id="chevron-down-00134c7b-b10c-486b-af1b-ea0801b1e1c2" data-name="<Pfad>" points="140 4 72 72 4 4" stroke-linecap="round" stroke-linejoin="round"></polyline></svg>
		)
    }
}



export default withStyles(styles)(ArrowIcon);