import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Col, Row, Grid } from "react-bootstrap";
import { connect } from "react-redux";
//import 'font-awesome/css/font-awesome.min.css';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { translate } from "../../helpers";

const styles = (theme) => ({
  div: {
    position: "relative",
    display: "block",
    height: "100%",
    fontFamily: "VodafoneLight",
    backgroundColor: "#333",
    color: "#fff",
    padding: "20px 0 50px",
  },
  i: {
    borderRight: "1px solid",
    paddingRight: "10px",
    fontSize: "20px",
    fontStyle: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    },
  },
  li: {
    fontFamily: "VodafoneLight",
    fontSize: "20px",
    paddingBottom: "10px",
    textTransform: "capitalize",
  },
  social: {
    width: "36px",
    height: "36px",
    fill: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "28px",
      height: "28px",
    },
  },
  socialItem: {
    fontSize: "30px",
  },
  ul: {
    marginTop: "150px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "70px",
      textAlign: "center",
    },
  },
  button: {
    buttonSize: "700px",
  },
  copyright: {
    marginTop: "0px",
    paddingTop: "0px",
    display: "inline-block",
    float: "right",
    fontSize: "20px",
    fontFamily: "VodafoneRegular",
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
      float: "none",
      textAlign: "center",
    },
  },
  link: {
    color: "#fff",
    fontFamily: "VodafoneRegular",
    "&:hover": {
      color: "#fff",
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  footerMenu: {
    display: "inline-block",
  },
  iconContainer: {
    paddingRight: "15px!important",
    paddingLeft: "15px!important",
    "&:first-child": {
      paddingLeft: "0px!important",
    },
    "& svg": {
      width: "36px",
      height: "36px",
      fill: "#fff",
      [theme.breakpoints.down("sm")]: {
        width: "28px",
        height: "28px",
      },
    },
  },
  socialList: {
    margin: "35px 0 34px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "35px 0 40px",
    },
  },
  footerHeader: {
    fontSize: "28px",
    lineHeight: "34px",
    margin: "40px 0 30px",
  },
  footerTextIcon: {
    fontSize: "28px",
    lineHeight: "59px",
    margin: "40px 0 30px",
    marginRight: "10px",
    fontFamily: "VodafoneLight",
    fontWeight: "400",
    color: "#fff",
  },
  root: {
    width: "100%",
    backgroundColor: "#333",
  },
  heading: {
    fontSize: "20px",
    fontFamily: "VodafoneLight",
    color: "#fff",
  },
  expansionPanel: {
    backgroundColor: "#333",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    margin: "-1px",
    borderRadius: "0!important",
    boxShadow: "none",
    "&:first-child": {
      borderTop: 0,
    },
  },
  panelSummary: {
    padding: "0 10px 0 10px",
    width: "100%",
  },
  iconSummary: {
    fontSize: "36px",
    color: "#fff",
  },
});

class HomeFooter extends Component {
  render() {
    const { classes, footer, config } = this.props;

    var address_txt = translate("adresa", config);

    const getSocial = (social) => {
      try {
        return (
          <li className={["list-inline-item", classes.iconContainer].join(" ")}>
            <a
              target="_blank"
              href={footer.socialMedia[social].text}
              dangerouslySetInnerHTML={{
                __html: footer.socialMedia[social].value,
              }}
            ></a>
          </li>
        );
      } catch {
        return "";
      }
    };

    const getOtherSocial = () => {
      try {
        return (
          <li
            className={[
              "list-inline-item pull-right",
              classes.iconContainer,
            ].join(" ")}
          >
            <a href={footer.socialMedia.fondacioniVod.text}>
              <span className={classes.footerTextIcon}>
                {footer.socialMedia.fondacioniVod.value}
              </span>
              <svg className={classes.social}>
                <svg
                  viewBox="0 0 144 141.01"
                  id="icon-community"
                  width="100%"
                  height="100%"
                >
                  <title>community</title>{" "}
                  <circle
                    cx="72"
                    cy="119.18"
                    r="17.83"
                    strokeMiterlimit="10"
                  ></circle>
                  <circle
                    cx="146.17"
                    cy="66.78"
                    r="17.83"
                    transform="translate(-37.81 47.54) rotate(-30)"
                    strokeMiterlimit="10"
                  ></circle>
                  <circle
                    cx="45.83"
                    cy="66.78"
                    r="17.83"
                    transform="matrix(0.5, -0.87, 0.87, 0.5, -58.92, 38.58)"
                    strokeMiterlimit="10"
                  ></circle>
                  <path
                    d="M113.59,151a58,58,0,0,0,27.51-18.87"
                    transform="translate(-24 -34.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M50.89,132.08a58,58,0,0,0,27.49,18.87"
                    transform="translate(-24 -34.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M39.39,83.39A58.05,58.05,0,0,0,42,116.65"
                    transform="translate(-24 -34.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M87.09,38.5A57.71,57.71,0,0,0,57,52.91"
                    transform="translate(-24 -34.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M135,52.9A57.71,57.71,0,0,0,104.91,38.5"
                    transform="translate(-24 -34.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M150,116.65a58.05,58.05,0,0,0,2.58-33.25"
                    transform="translate(-24 -34.5)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </svg>
            </a>
          </li>
        );
      } catch {
        return "";
      }
    };

    return (
      <div className={classes.div}>
        <div color="elegant-color-dark" className="footer_navigation">
          <Grid className="text-left">
            <div>
              <ul
                className={[
                  "list-unstyled mt-100 list-inline",
                  classes.socialList,
                ].join(" ")}
              >
                {getSocial("facebook")}
                {getSocial("twitter")}
                {getSocial("instagram")}
                {getSocial("youtube")}
                {getOtherSocial()}
              </ul>
            </div>
            <Row>
              <Col xsHidden smHidden md={4}>
                <h2 className={classes.footerHeader}>{footer.footer.name}</h2>
                <ul className="list-unstyled">
                  {footer.footer.items.map((item) => {
                    return (
                      <li key={item.key} className={classes.li}>
                        <a className={classes.link} href="#!">
                          {" "}
                          {item.value}{" "}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Col>
              <Col xsHidden smHidden md={4}>
                <h2 className={classes.footerHeader}>{footer.footer1.name}</h2>
                <ul className="list-unstyled">
                  {footer.footer1.items.map((item) => {
                    return (
                      <li key={item.key} className={classes.li}>
                        <a className={classes.link} href="#!">
                          {" "}
                          {item.value}{" "}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Col>
              <Col xsHidden smHidden md={4}>
                <h2 className={classes.footerHeader}>{footer.footer2.name}</h2>
                <ul className="list-unstyled">
                  {footer.footer2.items.map((item) => {
                    return (
                      <li key={item.key} className={classes.li}>
                        <a className={classes.link} href="#!">
                          {" "}
                          {item.value}{" "}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Col>

              <Col mdHidden lgHidden sm={12}>
                <div className={classes.root}>
                  <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                      className={classes.panelSummary}
                      expandIcon={
                        <ExpandMoreIcon className={classes.iconSummary} />
                      }
                    >
                      <Typography className={classes.heading}>
                        {footer.footer.name}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography>
                        <ul className="list-unstyled">
                          {footer.footer.items.map((item) => {
                            return (
                              <li key={item.key} className={classes.li}>
                                <a className={classes.link} href="#!">
                                  {" "}
                                  {item.value}{" "}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                      className={classes.panelSummary}
                      expandIcon={
                        <ExpandMoreIcon className={classes.iconSummary} />
                      }
                    >
                      <Typography className={classes.heading}>
                        {footer.footer1.name}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography>
                        <ul className="list-unstyled">
                          {footer.footer1.items.map((item) => {
                            return (
                              <li key={item.key} className={classes.li}>
                                <a className={classes.link} href="#!">
                                  {" "}
                                  {item.value}{" "}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                      className={classes.panelSummary}
                      expandIcon={
                        <ExpandMoreIcon className={classes.iconSummary} />
                      }
                    >
                      <Typography className={classes.heading}>
                        {footer.footer2.name}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography>
                        <ul className="list-unstyled">
                          {footer.footer2.items.map((item) => {
                            return (
                              <li key={item.key} className={classes.li}>
                                <a className={classes.link} href="#!">
                                  {" "}
                                  {item.value}{" "}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </Col>
            </Row>
            <div className={classes.ul}>
              <ul
                className={[
                  classes.footerMenu,
                  "list-unstyled mt-200 list-inline",
                ].join(" ")}
              >
                <li>
                  <a className={classes.link}>
                    <i className={classes.i}> Site Map </i>
                  </a>
                </li>
                <li>
                  <a className={classes.link}>
                    <i className={classes.i}> Kushte & Afate</i>
                  </a>
                </li>
                <li>
                  <a
                    className={classes.link}
                    href="https://www.vodafone.al/informacion-ligjor/informacion-i-pergjithshem-ligjor/rregullore-e-privatesise/"
                  >
                    <i className={classes.i}> Politika e Privatesise </i>
                  </a>
                </li>
              </ul>
              <div
                className={[classes.copyright, "copyright text-right"].join(
                  " "
                )}
              >
                <span> {address_txt} </span>
              </div>
            </div>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { config } = state;

  var footer = {
    footer: { name: "", items: [] },
    footer1: { name: "", items: [] },
    footer2: { name: "", items: [] },
    socialMedia: { name: "", items: [] },
  };

  if (typeof config.pages.footer != "undefined") {
    footer = config.pages;
  }

  return { footer, config };
};

export default connect(mapStateToProps)(withStyles(styles)(HomeFooter));
