import React, { Component } from "react";
import Authentication from "../../components/user";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  sendPin,
  register,
  login,
  showForgotForm,
  passwordPinReceived,
  resetPassword,
  forgotPasswordNumber,
  cancelAction,
  checkLogin,
  vodafoneIdLogin,
  preAuthorize,
} from "../../actions/user";
import { getConfig } from "../../actions";
import Header from "../header";
import { push } from "connected-react-router";
import { withRouter, Redirect } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

class UserContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      password: "",
      pin: "",
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getConfig());

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (code && state) {
      dispatch(vodafoneIdLogin(code, state));
      localStorage.setItem("IDLogin", "true");
      dispatch({
        type: "IDAAS_CHECK_SEND",
      });
    } else {
      dispatch({
        type: "IDAAS_CHECK_RECEIVED",
      });
      localStorage.removeItem("IDLogin");
    }
  }

  handleRegister = (event) => {
    event.preventDefault();
    let phone = event.target.elements.phone.value;
    let password = event.target.elements.password.value;

    this.setState({
      phone: phone,
      password: password,
    });

    const { dispatch } = this.props;

    dispatch(sendPin(phone));
  };

  handleLogin = (event) => {
    event.preventDefault();
    let phone = event.target.elements.phone.value;
    let password = event.target.elements.password.value;

    this.setState({
      phone: phone,
      password: password,
    });

    const { dispatch } = this.props;

    dispatch(checkLogin(phone, password));
    //dispatch(sendPin(phone));
  };

  handleIdaaSLogin = async (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    const { state, redirect_url } = await dispatch(preAuthorize());

    if (state && redirect_url) {
      window.location.href = redirect_url;
    }
  };

  handleRegisterPinSubmit = (event) => {
    event.preventDefault();
    let pin = event.target.elements.pin.value;

    const { phone, password } = this.state;

    let data = {
      phone: phone,
      password: password,
      pin: pin,
    };

    const { dispatch } = this.props;

    dispatch(register(data));
  };

  handleLoginPinSubmit = (event) => {
    event.preventDefault();
    let pin = event.target.elements.pin.value;

    const { phone, password } = this.state;

    let data = {
      phone: phone,
      password: password,
      pin: pin,
    };

    const { dispatch } = this.props;

    dispatch(login(data));
  };

  handleForgotClick = () => {
    const { dispatch } = this.props;
    dispatch(showForgotForm());
  };

  handleNumberSubmit = (event) => {
    event.preventDefault();
    let phone = event.target.elements.phone.value;

    const { dispatch } = this.props;

    dispatch(forgotPasswordNumber(phone));
    dispatch(sendPin(phone));
  };

  handleForgotPinSubmit = (event) => {
    event.preventDefault();
    let pin = event.target.elements.pin.value;
    const { dispatch } = this.props;

    dispatch(passwordPinReceived(pin));
  };

  handlePasswordReset = (event) => {
    event.preventDefault();
    let password = event.target.elements.password.value;

    const { dispatch, forgotPassword } = this.props;

    dispatch(
      resetPassword(forgotPassword.number, password, forgotPassword.pin)
    );
  };

  handleCancel = () => {
    const { dispatch } = this.props;
    dispatch(cancelAction());
  };

  render() {
    const {
      getPin,
      register,
      login,
      forgotPassword,
      config,
      idassLogin,
    } = this.props;

    const token = localStorage.getItem("token");
    const IDLogin = localStorage.getItem("IDLogin");

    if (token) {
      return <Redirect to="/home" />;
    }

    if (IDLogin && idassLogin.checking) {
      return (
        <div
          style={{
            background: "#333333",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress size={150} />
        </div>
      );
    }

    return (
      <div
        style={{
          backgroundImage: `url("/background-1.jpg")`,
          height: "100%",
          width: "100%",
          backgroundSize: "cover", // cover the entire viewport
          backgroundRepeat: "no-repeat", // no repeat
          backgroundPosition: "center center",
        }}
      >
        <div>
          <img />
        </div>
        <Header handleIdaaSLogin={this.handleIdaaSLogin} />

        <div>
          <Authentication
            handleRegister={this.handleRegister}
            handleLogin={this.handleLogin}
            handleIdaaSLogin={this.handleIdaaSLogin}
            getPin={getPin}
            handleRegisterPinSubmit={this.handleRegisterPinSubmit}
            handleLoginPinSubmit={this.handleLoginPinSubmit}
            register={register}
            login={login}
            idassLogin={idassLogin}
            forgotPassword={forgotPassword}
            handleForgot={this.handleForgotClick}
            handleNumberSubmit={this.handleNumberSubmit}
            handleForgotPinSubmit={this.handleForgotPinSubmit}
            handlePasswordReset={this.handlePasswordReset}
            handleCancel={this.handleCancel}
            config={config}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { getPin, register, login, forgotPassword, config, idassLogin } = state;

  return {
    getPin,
    register,
    login,
    forgotPassword,
    config,
    idassLogin,
  };
};

// const mapDispatchToProps = dispatch => bindActionCreators({
// 	sendPin
// }, dispatch);

export default withRouter(connect(mapStateToProps)(UserContainer));
