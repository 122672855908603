export function translate(word, config){
	try{
    	var translation = config.pages.login[word].value;
    	return translation;
    }catch{

    	try{
			var translation = config.pages.dashboard[word].value;
			return translation;
    	}catch{
			return '';
    	}
    }
}

export function getRegex(type, config){
    try{
        var regex = config.config.regex[type];
        return regex;
    }catch{
        return '';
    }
}