import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  loaderSpinner: {
    border: '8px solid #666666',
    height: '70px',
    width: '70px',
    borderTopColor: '#CCCCCC !important',
    borderRadius: '50%',
    position: 'relative',
    WebkitAnimation: 'loader-rotate 1s linear infinite',
    animation: 'loader-rotate 1s linear infinite',
    margin: '0 auto',
  },
  loaderText: {
    color: '#333333',
    margin: '20px -15px',
    fontSize: '20px',
    textAlign: 'center',
  }
});

class Loading extends Component {
  

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.loaderSpinner}></div> 
        <div className={classes.loaderText}>Ju lutem, prisni</div>
      </div>
    );
  }


}


export default withStyles(styles)(Loading);