import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Panels from "./Panels";
import { connect } from "react-redux";
import { translate } from "../../helpers";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#f4f4f4",
  },
  mainText: {
    fontSize: "49px",
    textAlign: "center",
    fontWeight: 300,
    padding: "50px",
    color: "#333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      paddingTop: "24px",
      paddingBottom: "27px",
    },
  },
  paper: {
    "box-shadow": "none",
    borderBottom: "1px solid #ccc",
    background: "transparent",
  },
  tab: {
    fontSize: "15px",
  },
  containers: {
    padding: "24px",
    [theme.breakpoints.down("sm")]: {
      padding: "12px",
    },
  },
});

class HeaderTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes, config } = this.props;
    const { value } = this.state;

    var invoice = translate("shikoFatElekVod", config);
    var number = localStorage.getItem("phone");

    return (
      <div className={classes.root}>
        <Typography component="h1" className={classes.mainText}>
          {invoice}
        </Typography>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <Tabs
              value={value}
              onChange={this.handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              centered
              className={classes.tabs}
            >
              <Tab className={classes.tab} label={number} />
            </Tabs>
          </Paper>

          <Typography component="div" className={classes.containers}>
            <Panels />
          </Typography>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { config } = state;

  return { config };
};

export default connect(mapStateToProps)(withStyles(styles)(HeaderTabs));
