import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import CancelButton from '../CancelButton';
import { translate, getRegex } from '../../../helpers';
import InputError from '../InputError';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    //marginLeft: theme.spacing.unit * 3,
    //marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
},
paper: {
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 15px 24px',
    background: 'transparent',
    boxShadow: 'none',
},
form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
},
submit: {
    marginTop: theme.spacing.unit * 3,
    backgroundColor: '#be1c02',
    borderColor: '#be1c02',
    color: '#fff',
    borderRadius: 0,
    fontSize: '20px',
    textTransform: 'capitalize',
    padding: '11px 37px',
    lineHeight: '27px',
    opacity: '1!important',
},
formInput:{
    background: '#fff',
    marginTop: '5px',
    marginBottom: '25px',
},
input: {
    padding: '13px 20px 12px',
    fontSize: '20px',
    height: '50px',
    fontFamily: "VodafoneRegular",
    boxSizing: 'border-box',
    border: '1px solid #999',
},
label: {
    color: '#fff',
    fontSize: '18px',
    fontWeight: 500,
}
});

class ForgotPassword extends Component{
    constructor(props){
      super(props);
      this.state = {
        phone: {
          valid: true,
          message: 'Numër i pavlefshëm',
          initial: true,
        },
        button: {
          color: 'primary',
          disabled: true,
          class: 'submitDisabled',
        }
      }
    }

    handleInputKeyUp = (e) => {

        var element = e.target;

        var phone = document.getElementById('phone').value;

        var config = this.props.config;

        var phone_exp = RegExp(getRegex('phoneNumber', config));

        var valid_phone = phone_exp.test(phone);
    


      this.setState({
        [e.target.name]: {
          ...this.state[e.target.name],
          initial: false,
          valid: true
        }
      });
      
      if(valid_phone){
        this.setState({
          button: {
            ...this.state.button,
            color: 'secondary',
            disabled: false,
            class: '',
          }
        });
      }else{
        this.setState({
          button: {
            ...this.state.button,
            color: 'primary',
            disabled: true,
            class: 'submitDisabled',
          }
        });
      }

    }


    handleInputBlur = (e) =>{
      if(e.target.value.length > 0){

        var config = this.props.config;

        var phone_exp = RegExp(getRegex('phoneNumber', config));

        var message = translate('login_ht_username_err_hint', config);
        
        if(e.target.name == 'phone'){
          var valid = phone_exp.test(e.target.value);
        }

        this.setState({
          [e.target.name]: {
            ...this.state[e.target.name],
            valid: valid,
            initial: false,
            message
          }
        })
      }else{
        this.setState({
          [e.target.name]: {
            ...this.state[e.target.name],
            initial: true
          }
        })
      }
      
    }

    render(){

        const { classes, handleCancel, config } = this.props;
        
        var username = translate('login_lb_username', config);
        var username_hint = translate('login_ht_username_hint', config);
        var confirm = translate('login_tx_confirm', config);

        var phone_exp = getRegex('phoneNumber', config);
        
        return (
            <main className={classes.main}>
                <Paper className={classes.paper}>
                    <form className={classes.form} onSubmit={this.props.handleNumberSubmit}>
                        <InputError error={this.state.phone}/>
                        <InputLabel htmlFor="phone" className={classes.label}>{username}</InputLabel>
                        <FormControl margin="normal" required fullWidth className={classes.formInput}>
                            <Input onBlur={this.handleInputBlur} onKeyUp={this.handleInputKeyUp} id="phone" name="phone" autoComplete="phone" autoFocus placeholder={username_hint} classes={{input: classes.input}}/>
                        </FormControl>

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="secondary"
                          className={classes.submit}
                          disabled={this.state.button.disabled}
                        >
                            {confirm}
                        </Button>
                        <CancelButton config={config} handleCancel={handleCancel} />
                    </form>
                </Paper>
            </main>
        );
    }
}


export default withStyles(styles)(ForgotPassword);