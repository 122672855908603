import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { translate } from "../../helpers";

const styles = (theme) => ({
  headerText: {
    color: theme.palette.textPrimary,
    marginBottom: "40px",
    paddingTop: "80px",
    fontSize: "56px",
    lineHeight: "62px",
    fontFamily: "VodafoneLight",
  },
});

class Header extends Component {
  render() {
    const { classes, config, handleIdaaSLogin } = this.props;

    var main_txt = translate("login_tx_header", config);

    return (
      <header>
        <Typography
          variant="h3"
          gutterBottom
          align="center"
          style={{ cursor: "pointer" }}
          className={classes.headerText}
          onClick={handleIdaaSLogin}
        >
          {/* {main_txt} */}
        </Typography>
        <Typography
          variant="h3"
          gutterBottom
          align="center"
          className={classes.headerText}
        >
          {/* {main_txt} */}
        </Typography>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  const { config } = state;

  return { config };
};

export default connect(mapStateToProps)(withStyles(styles)(Header));
